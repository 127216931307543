import { Component, createSignal, onMount, Show } from "solid-js";
import { LanguageSelector } from "../components/universal/language-selector/index";
import { A, useNavigate } from "@solidjs/router";
import { Offcanvas } from "solid-bootstrap";
import { Login } from "./login";
import { UserSession } from "../types";
import { CMS } from "../components/cms/cms";
import { createStore } from "solid-js/store";
import { loadCms } from "../app";

type State = {
    isContentReady: boolean;
}
export const JetMenu : Component<any> = () => {
    const navigate = useNavigate();
    const [state, setState] = createStore<State>({ isContentReady: false });
    const [session, setSession] = createStore<UserSession>({
        isAdmin: false,
        canApply: false,
        itemCount: 0,
        cultureCode: "",
        isLoggedIn: false,
        isRegistered: false,
        isHighSchoolStudent: false,
        member: {
            id: 0, firstName: "", lastName: "", permissions: [],
            memberType: "", username: ""
        }    
    } as UserSession);
    const hasPermission = (permission: string) => {
        if (!session.isLoggedIn) return false;
        return session.member?.permissions?.some(p => p == "Everything" || permission == p);
    };
    const logoutClicked = async (e: Event) => {
        e.preventDefault();
        const response = await fetch("/SalonApplication/Salon/LogoutJson");
        const json = await response.json();
        if (!json.Success) return;
        setSession({ isLoggedIn: false });
    };
    const loginClicked = (e: Event) => {
        e.preventDefault();
        handleOpenLogin();
    };
    const [showLogin, setShowLogin] = createSignal(false);
    const handleOpenLogin = () => setShowLogin(true);
    const handleCloseLogin = () => setShowLogin(false);
    const onLoginCancel = () => handleCloseLogin();
    const onLoginSuccess = (session: UserSession) => {
        handleCloseLogin();
        setSession(session);
    };
    const loadApplicationStatus = async () => {
        const res = await fetch("/SalonApplication/Salon/GetApplicationStatusJson");
        const json = await res.json();
        if (!json.Success) {
            setSession({
                isAdmin: false,
                cultureCode: "",
                isRegistered: false,
                isLoggedIn: false,
                canApply: false
            });
            return;
        }
        setSession(json.Data);
    };
    const onLoginRegistrationRequired = () => {
        handleCloseLogin();
        setSession({
            isLoggedIn: true,
            isRegistered: false
        });
        navigate("/step-1");
    };
    onMount(async () => {
        await loadCms([
            "nav::apply",
            "nav::introduction","nav::my-profile","nav::my-artworks",
            "nav::my-artworks","nav::rules","nav::categories",
            "nav::awards","nav::jurors","nav::sponsors",
            "nav::about-us","nav::log-out","nav::log-in","nav::welcome-username"
        ]);
        await loadApplicationStatus();
        setState({ isContentReady: true });
    })
    return (
    <> 
        <div class="navbar-fixed-top salon-navbar">
            <Show when={state.isContentReady}>
                <ul class="jetmenu blue">
                    <li class="left">
                        <a href="/"><span class="fa fa-home"></span></a>
                    </li>
                    <Show when={session.isLoggedIn}>
                        <Show when={session.isRegistered}>
                            <li>
                                <A href={"/introduction"}><CMS text id="nav::introduction">Introduction</CMS></A>
                            </li>
                            <Show when={session.canApply}>
                                <li>
                                    <A href={"/step-1"}><CMS text id="nav::my-profile">My Profile</CMS></A>
                                </li>
                            </Show>
                            <li>
                                <A href={"/step-2"}><CMS text id="nav::my-artworks">My Artworks</CMS></A>
                            </li>
                        </Show>
                    </Show>
                    <Show when={session.canApply}>
                        <li>
                            <A href={"/"}><CMS text id="nav::apply">Apply</CMS></A>
                        </li>
                    </Show>
                    <li>
                        <A href={"/rules"}><CMS text id="nav::rules">Rules</CMS></A>
                    </li>
                    <li>
                        <A href={"/categories"}><CMS text id="nav::categories">Categories</CMS></A>
                    </li>
                    <li>
                        <A href={"/awards"}><CMS text id="nav::awards">Awards</CMS></A>
                    </li>
                    <li>
                        <A href={"/jurors"}><CMS text id="nav::jurors">Jurors</CMS></A>
                    </li>
                    <li>
                        <A href={"/sponsors"}><CMS text id="nav::sponsors">Sponsors</CMS></A>
                    </li>
                    <li>
                        <a href={"/AboutUs/Staff"}><CMS text id="nav::about-us">About Us</CMS></a>
                    </li>
                    <li class="right" style="padding: 8px;">
                        <LanguageSelector cultureCode={session.cultureCode?.substring(0,2)} />
                    </li>
                    <Show when={session.isLoggedIn}>
                        <li>
                            <span onClick={logoutClicked}><CMS text id="nav::log-out">Log Out</CMS></span>
                        </li>
                        <Show when={session.isRegistered}>
                            <li>
                                <A href={"/step-3"}>
                                    <span class="glyphicon glyphicon-shopping-cart">
                                        <Show when={session.itemCount}>
                                            <span class="badge" style="background-color: red; font-size: 0.75em;">{session.itemCount}</span>
                                        </Show>
                                    </span>
                                </A>
                            </li>
                        </Show>
                    </Show>
                    <Show when={!session.isLoggedIn}>
                        <li>
                            <span onClick={loginClicked}><CMS text id="nav::log-in">Log In</CMS></span>
                        </li>
                    </Show>
                    <Show when={session.isLoggedIn}>
                        <li>
                            <p class="nav-account hidden-md hidden-sm hidden-xs">
                                <CMS text id="nav::welcome-username" data={{ username: session.member.username }}>Welcome, {session.member.username}</CMS>
                            </p>
                        </li>
                    </Show>
                    <li>
                    </li>
                </ul>
                <Offcanvas show={showLogin()} placement="end" onHide={handleCloseLogin}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title><CMS id="nav::log-in">Log In</CMS></Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Login
                            onCancel={onLoginCancel}
                            onSuccess={onLoginSuccess}
                            onRegistrationRequired={onLoginRegistrationRequired}
                        />
                    </Offcanvas.Body>
                </Offcanvas>
            </Show>
        </div>
    </>
    );
};