import { A } from "@solidjs/router"

export function PaymentProcessedPage() {
    return (
        <>
            <div class="blank-page">
                <p>
                    Your payment has been processed!
                </p>
                <p>
                    You can now view your <A href="/step-3">Invoice</A> or return to <A href="/step-2">Your Artworks</A>.
                </p>
            </div>
        </>
    )
}