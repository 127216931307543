import { Route, Router, Routes, hashIntegration, useParams } from "@solidjs/router";
import { Component, ErrorBoundary, createSignal, onMount } from "solid-js";
import { EntrantProfile, Step1RouteData, EditArtworkModel, EditArtworkData,  UserSession, ArtworkImageData, Ad } from "./types";
import { Step1Page } from "./pages/step-1";
import { Step2Page } from "./pages/step-2";
import { EditArtwork } from "./pages/edit-artwork";
import { Introduction } from "./pages/introduction";
import { fetchGet, formatText, getElementsFromHtml, preloadImages } from "./library/common";
import { EditImage } from "./pages/edit-image";
import { JetMenu } from "./sections/jetmenu";
import { LogoSlider } from "./sections/logo-slider";
import { Header } from "./sections/header";
import { Footer } from "./sections/footer";
import { BackToTop } from "./components/universal/back-to-top";
import { createStore } from "solid-js/store";
import { Step3Page } from "./pages/step-3";
import { CreditCardPage } from "./pages/credit-card";
import { PaypalConfirmedPage } from "./pages/paypal-confirmed";
import toast, { Toaster } from "solid-toast";
import { CMSBlock, CMSProvider } from "./components/cms/provider";
import { CMS, plainText } from "./components/cms/cms";
import _ from "underscore";
import { CategoriesPage } from "./pages/categories";
import { RulesPage } from "./pages/rules";
import { AwardsPage } from "./pages/awards";
import { SponsorsPage } from "./pages/sponsors";
import { JurorsPage } from "./pages/jurors";
import { JL } from "jsnlog";
import { getOrSetAsync } from "./API";
import { PaymentProcessedPage } from "./pages/payment-processed";

export const Payment : Component<any> = (props) => <div></div>;
export const Rules : Component<any> = (props) => <div></div>;

export const [ads, setAds] = createSignal<Ad[]>([]);
const loadAds = async (section: string) => {
    const res = await fetchGet("/16thARCSalon/Home/GetAdsJson", { section });
    const json = await res.json();
    if (!json.Success) return;
    setAds(json.Data);
};
export const [cms, setCms] = createStore<Record<string,CMSBlock>>({});
export const [cache, setCache] = createStore<Record<string,any>>({});
export const getOrSetCache = async (key: string, getter: () => Promise<any>) => {
    const item = cache[key];
    if (item != undefined) return item;
    const value = await getter();
    setCache(key, value);
    return value;
};

export const loadCms = async (ids: string[]) => {
    const cachedIds = Object.keys(cms);
    const uncachedIds = ids.filter(id => !cachedIds.some(key => key == id));
    if (!uncachedIds.length) return;
    let json: any;
    try {
        const res = await fetchGet("/Home/GetContentBlocksJson", { ids: uncachedIds.join(",") });
        json = await res.json();
        if (!json.Success) return;
    } catch (ex) {
        console.log("loadCms", { ex });
        return;
    }
    let data: Record<string, CMSBlock> = {};
    uncachedIds.forEach(id => {
        let value = json.Data[id];
        if (!value || value.length === 0) {
            data[id] = { found: false, item: null };
            return;
        }
        data[id] = { found: true, item: value };
    });
    setCms(data);
};
export const loadIntroductionCms = async () => {
    await loadCms([
        "17th-arc-salon-application/home::introduction",
        "salon-application/home::rules/heading", "salon-application/home::rules/image", "salon-application/home::rules/blurb",
        "salon-application/home::categories/heading", "salon-application/home::categories/image", "salon-application/home::categories/blurb",
        "salon-application/home::awards/heading", "salon-application/home::awards/image", "salon-application/home::awards/blurb",
        "salon-application/home::jurors/heading", "salon-application/home::jurors/image", "salon-application/home::jurors/blurb",
        "salon-application/home::sponsors/heading", "salon-application/home::sponsors/image", "salon-application/home::sponsors/blurb",
        "button::update/start-application",
        "button::learn-more"
    ]);
}
export const loadAwardsCms = async () => {
    await loadCms([
        "salon-application/awards::page-body"
    ]);
}
export const loadCategoriesCms = async () => {
    await loadCms(["salon-application/categories::page-body"]);
};
export const loadJurorsCms = async () => {
    await loadCms([
        "salon-application/jurors::juror-listing",
        "salon-application/jurors::description",
        "label::jurors",
        "jurors::fred-ross",
        "jurors::kara-lysandra-ross",
        "jurors::vern-swanson",
        "jurors::michael-john-angel",
        "jurors::juliette-aristides",
        "jurors::daniel-graves",
        "jurors::anthony-waichulis",
        "jurors::patrick-wilshire",
        "jurors::eric-rhoads",
        "label::guest-jurors",
        "jurors::mary-pettis",
        "jurors::maudie-brady",
        "jurors::renee-bemis",
        "jurors::arantzazu-martinez",
        "jurors::xu-mangyao",
        "jurors::leng-jun",
        "jurors::qin-ming",
        "jurors::bridget-macavoy",
        "jurors::bai-yuping",
        "jurors::thomas-kegler",
        "jurors::richard-macdonald",
        "jurors::julie-bell",
        "jurors::mario-andres-robinson",
        "jurors::jim-mcvicker",
        "jurors::annie-murphy-robinson"
    ]);
}
export const loadStep1Cms = async () => {
    const ids = [
        "salon-application/step-1::your-details/heading","salon-application/step-1::your-details/description",
        "salon-application/step-1::section/name",
        "label::first-name","label::last-name",
        "salon-application/step-1::section/contact-details",
        "label::email","label::street-line-1","label::street-line-2","label::city","label::state-province","label::post-code-zip","label::country",
        "salon-application/step-1::label/phone-number",
        "salon-application/step-1::section/social-media",
        "label::facebook","label::instagram","label::twitter",
        "salon-application/step-1::section/biography",
        "salon-application/step-1::section/profile-picture",
        "salon-application/step-1::section/biography/criteria",
        "salon-application/step-1::section/catalogues",
        "salon-application/step-1::label/want-free-catalogue",
        "salon-application/step-1::label/pre-order-additional-copies",
        "salon-application/step-1::label/maximum-catalogues",
        "salon-application/step-1::label/example-catalogue",
        "salon-application/step-1::section/for-students",
        "salon-application/step-1::label/checkbox-minimum-age",
        "label::age", "salon-application/step-1::heading/student-details",
        "salon-application/step-1::section/high-school-student",
        "salon-application/step-1::parent-or-legal-guardian/heading",
        "label::name","label::email","label::phone-number","label::high-school","label::high-school-art-instructor",
        "salon-application/step-1::label/claims-to-attend-title1",
        "salon-application/step-1::section/rules",
        "salon-application/step-1::label/agreed-to-terms-and-conditions",
        "button::continue","button::yes-sign-me-up"
    ];
    await loadCms(ids);
}
export const loadCreditCardCms = async () => {
    const ids = [
        "label::postal-code-zip",
        "label::credit-card-number",
        "label::expiry-mm-yy",
        "label::ccv",
        "label::street",
        "label::pay-with-credit-card",
        "button::cancel",
        "button::pay-now",
        "salon-application/credit-card::total-owing"
    ];
    await loadCms(ids);
}
export const loadStep3Cms = async () => {
    const ids = [
        "salon-application/checkout::invoice",
        "salon-application/checkout::description",
        "salon-application/checkout::price",
        "salon-application/checkout::nothing-in-cart",
        "salon-application/checkout::total",
        "salon-application/checkout::amount-already-paid",
        "salon-application/checkout::amount-owing",
        "salon-application/checkout::amount-due-to-complete-entry",
        "salon-application/checkout::payment-method",
        "salon-application/checkout::fully-paid",
        "salon-application/checkout::credit-card",
        "salon-application/checkout::paypal",
        "salon-application/checkout::unable-to-update-number-of-catalogues-error",
        "salon-application/checkout::unable-to-update-shipping-address-error",
        "salon-application/checkout::retrieving-latest-invoice-error",
        "salon-application/checkout::paypal-transaction-error",
        "salon-application/checkout::error/credit-card-payment-issue"
    ];
    await loadCms(ids);
}
export const loadStep2Cms = async () => {
    const ids = [
        "label::artwork-added", "label::artworks-added",
        "salon-application/step-2::instructions",
        "salon-application/step-2::more-artworks-required",
        "salon-application/step-2::more-artworks-available",
        "button::back","button::next","button::add-artwork"
    ];
    await loadCms(ids);
}
export const loadSponsorsCms = async () => {
    await loadCms([
        "salon-application/sponsors::page-body"
    ]);
}
export const loadRulesCms = async () => {
    await loadCms([
        "salon-application/rules::page-body",
        "button::start-application","button::update-application"
    ]);
}
export const getText = (id: string, backup?: string, data?: Record<string, any>) => {
    const html = cms[id]?.item;
    if (!html) {
        return data && backup ? formatText(backup, data) : backup || "";
    }
    let text = plainText(html);
    if (data) {
        text = formatText(text, data);
    }
    return text;
}
enum Claim {
    IsLoggedIn,
    CanApply,
    IsRegistered
}
export const getApplicationStatusJson = async (options?: { forceRefresh: boolean }) => getOrSetAsync("application-status", async () => {
    const res = await fetch("/SalonApplication/Salon/GetApplicationStatusJson");
    const json = await res.json();
    return json;
}, options);
export const App : Component = () => {
    const authorised = async (claims: Claim[]) => {
        const json = await getApplicationStatusJson();
        if (!json.Success) {
            toast.error("Unable to contact the server just now. Please try reloading the page.");
            return false;
        }
        const data = json.Data;
        if (claims.some(c => c == Claim.IsLoggedIn)) {
            if (!data.isLoggedIn) {
                toast.error("You must be logged in to access this page.");
                return false;
            }
        }
        if (claims.some(c => c == Claim.CanApply)) {
            if (!data.canApply) {
                if (data.isAdmin) {
                    toast.error("The ARC Salon Application has not yet opened: but Admin is granted access.");
                    return true;
                }
                toast.error("The ARC Salon Application has not yet opened.");
                return false;
            }
        }
        if (claims.some(c => c == Claim.IsRegistered)) {
            if (!data.isRegistered) {
                toast.error("You must complete the profile form on Step 1 to continue.");
                return false;
            }
        }
        return true;
    }
    const getStep1_Data = async () => {
        const res = await fetch("/SalonApplication/Salon/GetStep1Json");
        const json = await res.json();
        return json.Data;
    }
    const getStep1_Resources = async () => getOrSetCache("/SalonApplication/Salon/GetResourcesJson", async () => {
        const res = await fetch("/SalonApplication/Salon/GetResourcesJson");
        const json = await res.json();
        return json.Data;
    });
    const fetchStep1Data = async ({ navigate }: any) => {
        if (!await authorised([Claim.CanApply])) {
            return navigate("/not-authorised");
        }
        if (!await authorised([Claim.IsLoggedIn])) {
            return navigate("/not-logged-in");
        }
        const _step1 = await getStep1_Data();
        const step1 = {
            id: _step1.Id,
            salonId: _step1.SalonId,
            biography: _step1.Biography,
            profile: {
                salonEntrantImageId: _step1.ArtistProfileImageId,
                imageUrl: _step1.ArtistProfileImageUrl
            },
            personal: {
                firstName: _step1.FirstName,
                lastName: _step1.LastName
            },
            address: {
                streetAddress1: _step1.StreetAddress1,
                streetAddress2: _step1.StreetAddress2,
                city: _step1.City,
                state: _step1.State,
                countryId: _step1.CountryId,
                postalCode: _step1.PostalCode
            },
            catalogue: {
                wantsFree: _step1.WantsFreeCatalogue,
                wantsAdditional: !!_step1.AdditionalCataloguesOrdered,
                additionalOrdered: _step1.AdditionalCataloguesOrdered || 0
            },
            isStudent: _step1.IsHighSchoolStudent,
            student: {
                age: _step1.Age,
                school: {
                    name: _step1.HighSchool,
                    instructors: _step1.HighSchoolInstructors,
                    claimsIsTitle1: _step1.ClaimsToAttendTitle1HighSchool,
                    isTitle1: _step1.AttendsTitle1HighSchool,
                },
                parentOrLegalGuardian: {
                    name: _step1.ParentOrLegalGuardianName,
                    email: _step1.ParentOrLegalGuardianEmail,
                    phoneNumber: _step1.ParentOrLegalGuardianPhoneNumber
                }
            },
            socials: {
                facebook: _step1.Facebook,
                twitter: _step1.Twitter,
                instagram: _step1.Instagram,
            },
            contact: {
                email: _step1.Email,
                phoneNumber: _step1.PhoneNumber
            },
            acceptedTermsAndConditions: _step1.AcceptedTermsAndConditions
        } as EntrantProfile;
        const resources = await getStep1_Resources();
        return {
            step1,            
            resources
        } as Step1RouteData;
    };
    const getStep3_Data = async () => {
        const res = await fetch("/SalonApplication/Salon/GetStep3DataJson");
        const json = await res.json();
        return json.Data;
    };
    const getStep3_Resources = async () => getOrSetCache("/SalonApplication/Salon/GetStep3ResourcesJson", async () => {
        const res = await fetch("/SalonApplication/Salon/GetStep3ResourcesJson");
        const json = await res.json();
        return json.Data;
    });
    const getAddress = async () => {
        const res = await fetch("/SalonApplication/Salon/GetAddressJson");
        const json = await res.json();
        return json.Data;
    }
    const fetchStep3Data = async ({ navigate }: any) => {
        try {
            if (!await authorised([Claim.IsLoggedIn])) {
                return navigate("/not-logged-in");
            }
            if (!await authorised([Claim.IsRegistered])) {
                return navigate("/step-1");
            }
            const data = await getStep3_Data();
            const resources = await getStep3_Resources();
            const address = await getAddress();
            return {
                address,
                data,
                resources
            };
        } catch (ex) {
            log("fetchStep3", { ex });
            JL().error("")
            navigate("/error", { state: { ex } });
        }
    };
    const getStep2_Data = async () => {
        const res = await fetch("/SalonApplication/Salon/GetStep2Json");
        const json = await res.json();
        return json.Data;
    };
    const getStep2_Resources = async () => {
        const res = await fetch("/SalonApplication/Salon/GetStep2ResourcesJson");
        const json = await res.json();
        return json.Data;
    }
    const fetchStep2Data = async ({ navigate }: any) => {
        try {
            if (!await authorised([Claim.IsLoggedIn])) {
                return navigate("/not-logged-in");
            }
            if (!await authorised([Claim.IsRegistered])) {
                return navigate("/step-1");
            }
            const artworks = await getStep2_Data();
            const resources = await getStep2_Resources();
            const session = await getApplicationStatusJson();
            return {
                session: session?.Data,
                artworks,
                resources
            };
        } catch (ex) {
            log("fetchStep2Data", { ex });
            navigate("/error", { state: { ex }});
        }
    }
    const fetchRulesData = async ({ navigate}:any) => {
        try {
            const sessionJson = await getApplicationStatusJson();
            return {
                session: sessionJson.Data
            };
        } catch (ex) {
            log("fetchRulesData", { ex });
            navigate("/error", { state: { ex }});
        }
    }
    const fetchPaypalCancelledData = async({ navigate }: any) => {
        try {
            // const params = useParams();
            // const token = params.token;
            toast.error("The Paypal transaction was cancelled on the Paypal website.");
            return fetchStep3Data({ navigate });
        }catch (ex) {
            log("fetchPaypalCancelledData", { ex });
            navigate("/error", { state: ex });
        }
    }
    const getUrlSearchParams = () => new URLSearchParams(window.location?.hash?.split("?")[1]);
    const fetchPaypalConfirmedData = async({ navigate }: any) => {
        try {
            const params = getUrlSearchParams();
            console.log("fetchPaypalConfirmedData", { params });
            return {
                token: params.get("token"),
                paymentId: params.get("paymentId"),
                payerId: params.get("PayerID")
            };
        } catch (ex) {  
            log("fetchPaypalConfirmedData", { ex });
            navigate("/error", { state: ex });
        }
    }
    const getArtwork = async (id: number) => {
        const response = await fetchGet("/SalonApplication/SalonSubmission/GetArtworkJson", { id });
        const json = await response.json();
        return json.Data;
    }
    const getArtworkImage = async (model: any) => {
        const response = await fetchGet("/SalonApplication/SalonSubmission/GetArtworkImageJson", model);
        const json = await response.json();
        return json.Data as ArtworkImageData;
    }
    const fetchArtworkImageData = async ({ params, navigate }: any) => {
        try {
            if (!await authorised([Claim.IsLoggedIn, Claim.IsRegistered])) {
                return navigate("/not-authorised");
            }
            if (!!params.artworkId) {
                const artwork = await getArtwork(params.artworkId);
                return {
                    id: "",
                    isPrimary: false,
                    isDeleted: false,
                    artwork: {
                        id: artwork.id,
                        title: artwork.title
                    },
                    newImage: {}
                } as ArtworkImageData;
            }
            if (params.artworkImageId === undefined) {                
                navigate("/not-found");
            }
            const artworkImageId = params.artworkImageId as number;
            const model = {
                artworkImageId
            };
            const artworkImage = await getArtworkImage(model);
            return artworkImage;
        }
        catch (ex) {
            log("fetchArtworkImageData", { ex });
            navigate("/error", { state: { ex }});
        }
    };
    const fetchIntroduction = async ({ navigate }: any) => {
        try {
            const session = await getApplicationStatusJson();
            log("fetchIntroduction",  { session});
            return { session: session.Data };
        } catch (ex) {
            log("fetchIntroduction", { ex });
            navigate("/error", { state: { ex }});
        }
    }
    const getCreditCardInfo = async () => {
        const response = await fetch("/SalonApplication/Salon/GetCreditCardJson");
        const json = await response.json();
        return json.Data;
    }
    const fetchCreditCard = async ({ navigate }: any) => {
        try {
            if (!await authorised([Claim.IsLoggedIn, Claim.IsRegistered])) {
                return navigate("/not-authorised");
            }
            const info = await getCreditCardInfo();
            return {
                entrant: info.entrant,
                address: info.address,
                resources: {
                    countries: info.resources.countries
                }
            };
        } catch (ex) {
            log("fetchCreditCard", { ex });
            navigate("/error");
        }
    };
    const log = (message: string, data: Record<string,any>) => {
        console.log(message, data);
    }
    const getEditArtworkResources = async () => {
        const res = await fetch("/SalonApplication/SalonSubmission/GetResourcesJson");
        const json = await res.json();
        return json.Data;
    }
    const fetchArtworkData = async ({ params, navigate }: any) => {
        try {
            if (!await authorised([Claim.IsLoggedIn])) {
                return navigate("/not-logged-in");
            }
            if (!await authorised([Claim.IsRegistered])) {
                return navigate("/step-1");
            }
            const session = (await getApplicationStatusJson()).Data;
            const resources = await getEditArtworkResources();
            const categories = resources.categories;
            if (params.id == undefined) {
                const data = {
                    session,
                    categories,
                    artwork: {
                        title: "",
                        medium: "",
                        purchaseAwardNominatedPrice: "",
                        width: "", height: "", depth: "", unitOfMeasurement: "ins",
                        categoryIds: [],
                        year: "2024",
                        location: "",
                        comment: "",
                        student: {
                            firstName: "",
                            lastName: "",
                            age: ""
                        },
                        framed: {}
                    } as any
                } as EditArtworkData;
                console.log("/add-artwork:fetchArtworkData", { data });
                return data;
            }
            const id = params.id as number;
            const response = await fetchGet("/SalonApplication/SalonSubmission/GetArtworkJson", { id });
            const json = await response.json();
            if (!json.Success) {
                navigate("/error");
                return;
            }
            return {
                session,
                categories,
                artwork: json.Data as EditArtworkModel
            } as EditArtworkData;
        } catch (ex) {
            log("fetchArtworkData", { ex });
            navigate("/error");
        }
    };
    const preload = async () => {
        /**
         * CMS
         */
        await loadCms([
            "17th-arc-salon-application/router::page/not-logged-in",
            "17th-arc-salon-application/router::page/not-found",
            "17th-arc-salon-application/router::page/not-authorised",
            "17th-arc-salon-application/router::page/error"
        ]);
        await loadStep1Cms();
        await loadStep2Cms();
        await loadStep3Cms();
        await loadCreditCardCms();
        await loadRulesCms();
        await loadAwardsCms();
        await loadCategoriesCms();
        await loadJurorsCms();
        await loadSponsorsCms();
        /**
         * Resources
         */
        await getStep1_Resources();
        await getStep2_Resources();
        await getStep3_Resources();
        /**
         * Images
         */
        preloadImagesFromCms([
            "salon-application/awards::page-body",
            "salon-application/categories::page-body",
            "salon-application/sponsors::page-body"
        ]);
        preloadImagesFromCms([
            "jurors::fred-ross",
            "jurors::kara-lysandra-ross",
            "jurors::vern-swanson",
            "jurors::michael-john-angel",
            "jurors::juliette-aristides",
            "jurors::daniel-graves",
            "jurors::anthony-waichulis",
            "jurors::patrick-wilshire",
            "jurors::eric-rhoads",
            "jurors::mary-pettis",
            "jurors::maudie-brady",
            "jurors::renee-bemis",
            "jurors::arantzazu-martinez",
            "jurors::xu-mangyao",
            "jurors::leng-jun",
            "jurors::qin-ming",
            "jurors::bridget-macavoy",
            "jurors::bai-yuping",
            "jurors::thomas-kegler",
            "jurors::richard-macdonald",
            "jurors::julie-bell",
            "jurors::mario-andres-robinson",
            "jurors::jim-mcvicker",
            "jurors::annie-murphy-robinson"
        ]);
    };
    const preloadImagesFromCms = (ids: string[]) => {
        const imgs =_.flatten(
            ids.map(id => getElementsFromHtml(cms[id]?.item, "img") as HTMLImageElement[])
        );
        const urls = imgs.map(x => x.src);
        preloadImages(urls);
    }
    onMount(async() => {
        await preload();
    });
    return (
    <Router source={hashIntegration()}>
        <div class="page-container">
            <CMSProvider cms={cms} setCms={setCms}>
                <Toaster />
                <JetMenu></JetMenu>
                <LogoSlider></LogoSlider>
                <Header></Header>
                <div class="container" style="padding-bottom: 40px;">
                    <ErrorBoundary fallback={err => {
                        JL().fatalException("[ SalonApplication ] Exception caught at the ErrorBoundary", err);
                        return (
                            <div class="blank-page">{err.toString()}</div>
                        )
                    }}>
                        <Routes>
                            {/* <Route path={["/step-1","/my-details"]} component={Step1Page} data={fetchStep1Data}></Route> */}
                            {/* <Route path={["/add-artwork","/edit-artwork/:id"]} component={EditArtwork} data={fetchArtworkData}></Route> */}

                            <Route path={"/categories"} component={CategoriesPage}></Route>
                            <Route path={"/jurors"} component={JurorsPage}></Route>
                            <Route path={"/rules"} component={RulesPage} data={fetchRulesData}></Route>
                            <Route path={"/awards"} component={AwardsPage}></Route>
                            <Route path={"/sponsors"} component={SponsorsPage}></Route>
                            <Route path={["/step-1","/my-details","/step-2", "/my-artworks"]} component={Step2Page} data={fetchStep2Data}></Route>
                            <Route path={["/edit-artwork/:id"]} component={EditArtwork} data={fetchArtworkData}></Route>
                            <Route path={["/add-image-to-artwork/:artworkId","/edit-image/:artworkImageId"]} component={EditImage} data={fetchArtworkImageData}></Route>
                            <Route path={["/step-3","/checkout"]} component={Step3Page} data={fetchStep3Data}></Route>
                            <Route path={"/credit-card"} component={CreditCardPage} data={fetchCreditCard}></Route>
                            <Route path={"/payment/paypal-confirmed"} component={PaypalConfirmedPage} data={fetchPaypalConfirmedData}></Route>
                            <Route path={"/payment/paypal-cancelled"} component={Step3Page} data={fetchPaypalCancelledData}></Route>
                            <Route path={"/payment/processed"} component={PaymentProcessedPage}></Route>
                            <Route path={"/not-logged-in"} component={() => {
                                return (
                                    <div class="blank-page">
                                        <CMS id="17th-arc-salon-application/router::page/not-logged-in">
                                            <p>
                                                You must be logged in to access this page.
                                            </p>
                                            <p>
                                                Please <a class="tape" href="/Home/Login">log in</a> to your ARC account or <a class="tape" href="/Home/Register">register</a> one to proceed.
                                            </p>
                                        </CMS>
                                    </div>
                                )
                            }}></Route>
                            <Route path={"/not-found"} component={() => {
                                return (
                                    <div class="blank-page">
                                        <CMS id="17th-arc-salon-application/router::page/not-found">
                                            Sorry, that page was not found
                                        </CMS>
                                    </div>
                                )
                            }}></Route>
                            <Route path={"/not-authorised"} component={() => {
                                return (
                                    <div class="blank-page">
                                        <CMS id="17th-arc-salon-application/router::page/not-authorised">
                                            You are not authorized to access the page at this time.
                                        </CMS>
                                    </div>
                                );
                            }}></Route>
                            <Route path={"/error"} component={() => {
                                const params = useParams();
                                return (
                                    <div class="blank-page">
                                        <h2>
                                            Sorry, there has been an error ...
                                        </h2>
                                        <div>
                                            
                                        </div>
                                    </div>
                                )
                            }}></Route>
                            <Route path={["/introduction","/"]} component={Introduction} data={fetchIntroduction}></Route>
                        </Routes> 
                    </ErrorBoundary>
                </div>
                <Footer></Footer>
            </CMSProvider>
        </div>
    </Router>);
}