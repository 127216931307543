import { Component, createEffect, splitProps } from "solid-js";
import { createStore } from "solid-js/store";
import { ListItem } from "../types";
import { Options } from "./options";

interface SelectProps extends Record<any, any> {
    value: string;
    onInput: (value: string) => void;
    list: ListItem[];
}
type Model = {
    value: string;
    list: ListItem[];
}
export const Select : Component<SelectProps> = (props) => {
    const [local, others] = splitProps(props, ["style"]);
    const [model, setModel] = createStore<Model>({
        value: "",
        list: []
    });
    createEffect(() => {
        if (props.list) {
            setModel({ list: props.list});
            setModel({ value: props.value });
        } else {
            setModel({ list: [] });
        }
    });
    createEffect(() => {
        if (props.value) {
            setModel({ value: props.value });
        } else {
            setModel({ value: "" });
        }
    });
    return (<select class="form-control"
        style={local.style}
        value={model.value}
        {...others}
        onInput={(e) => props.onInput(e.currentTarget.value)}><Options list={model.list} /></select>)
}