import { Component, For, Show, onMount } from "solid-js";
import { ProgressProps, Step } from "../types";
import { Link, useNavigate } from "@solidjs/router";
import { getText, loadCms } from "../app";
import { createStore } from "solid-js/store";

type State = {
    isContentReady: boolean;
}
export const Progress : Component<ProgressProps> = (props) => {
    const navigate = useNavigate();
    const [state, setState] = createStore<State>({ isContentReady: false });
    const steps: Step[] = [];
    const onStepClicked = async (target: Step) => {
        if (!props.onBeforeNavigatingToAnotherStep) {
            return navigate(target?.url || "/");
        }
        if (await props.onBeforeNavigatingToAnotherStep(target)) {
            return navigate(target?.url || "/");
        }
    };
    const stepState = (step: Step) => {
        const currentStep = step.index;
        if (currentStep < props.step)
        {
            return "complete";
        }
        if (currentStep == props.step)
        {
            return "active";
        }
        return "disabled";
    };
    onMount(async () => {
        await loadCms([
            "step-item::introduction",
            "step-item::step-1","step-item::my-details",
            "step-item::step-2","step-item::my-artworks",
            "step-item::step-3","step-item::checkout/invoice"
        ]);
        steps.push({ index: 0, name: getText("step-item::introduction", "Introduction"), url: "/introduction" });
        steps.push({ index: 1, name: getText("step-item::step-1", "Step 1"), label: getText("step-item::my-details", "My details"), url: "/step-1" });
        steps.push({ index: 2, name: getText("step-item::step-2", "Step 2"), label: getText("step-item::my-artworks", "My artworks"), url: "/step-2" });
        steps.push({ index: 3, name: getText("step-item::step-3", "Step 3"), label: getText("step-item::checkout/invoice", "Invoice"), url: "/step-3" });
        setState({ isContentReady: true });
    });
    return (
        <div>
            <div class="row bs-wizard" style="border-bottom:0; padding: 40px; position: sticky;">
                <Show when={state.isContentReady}>
                    <For each={steps}>{(step) =>
                        <div class={ "col-xs-2 bs-wizard-step " + stepState(step) }>
                            <div class="text-center bs-wizard-stepnum">
                                <Show when={step.url}>
                                    <a style="text-decoration: none; color: #333; font-family: 'Raleway-Regular';" onClick={(e) => { e.preventDefault(); onStepClicked(step); }}>{step.name}</a>
                                </Show>
                                <Show when={!step.url}>{step.name}</Show>
                            </div>
                            <div class="progress">
                                <div class="progress-bar"></div>
                            </div>
                            <Show when={!step.disabled}><Link href={step?.url || "/"} class="bs-wizard-dot"></Link></Show>
                            <div class="bs-wizard-info text-center">{step.label}</div>
                        </div>
                    }</For>
                </Show>
            </div>
        </div>
    )
}