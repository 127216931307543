import { Component, createSignal, onMount, Show } from "solid-js";
import { Img } from "../components/universal/image";
import { LatestBlogs } from  "./latest-blogs";

type Image = {
    URL: string;
};
type Ad = {
    URL: string;
    Image: Image;
};

export const Footer : Component = () => {
    const [ad, setAd] = createSignal<Ad>();
    const getAd = async () => {
        const response = await fetch("/Home/GetFooterAdsJson")
        const json = await response.json();
        return json;
    };
    const loadAd = async () => {
        const response = await getAd();
        if (!response.Success) return;
        setAd(response.Data[0]);
    };
    onMount(async() => {
        await loadAd();
    });
    return (
        <div style="margin:0; background-color: #928f7d;">
            <footer style="padding-top: 20px; color: #efefef; padding-bottom: 10px; font-size: 0.85em;">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <h2 style="font-size: 5em; font-weight: 600; margin-bottom: -10px; letter-spacing: -0.1em;">
                                ARC
                            </h2>
                            <p style="font-size: 1.5em;">
                                Leading the revival of realism
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <div style="width: 250px; height: 250px;">
                                <a href="/Calendar/Search">
                                    <Img src={"/Content/211/60e0bf88-14c5-4f18-b1fb-1e1281ea6794.jpg"} width={250} height={250} mode="crop" style="width: 100%;" />
                                </a>
                                {/* <Show when={ad()}>
                                    <a href={ad()?.URL}>
                                        <Img src={ad()?.Image.URL + "?w=250&h=250&mode=crop"} style="width: 100%;" />
                                    </a>
                                </Show>
                                <Show when={!ad()}>
                                    <a href="/Salon2016/Home/Exhibition">
                                        <Img src={"/Ad/27/c78ea09d-78be-45f4-851b-28ed6d9f1474.jpg"} width={250} height={250} mode="crop" style="width: 100%;" />
                                    </a>
                                </Show> */}
                            </div>
                        </div>
                        <div class="col-md-9">
                            <div class="row">
                                <div class="col-md-4">
                                    <h3 style="font-family: 'Oswald'; padding-bottom: 20px; margin: 0;">
                                        Latest News
                                    </h3>
                                    <LatestBlogs></LatestBlogs>
                                    <p style="margin-top: 10px;">
                                        <a href="/Calendar/Search">
                                            View our Events Calendar
                                        </a>
                                    </p>
                                    <p>
                                    Blog: <span style="margin-right: 10px;"><span style="color: #f26522;" class="fa fa-rss"></span> <a href="/Feed/Blog?format=rss">RSS</a></span> <span><span style="color: #f26522;" class="fa fa-rss"></span> <a href="/Feed/Blog?format=atom">Atom</a></span>
                                    </p>
                                    <p>
                                        Recent Additions: <span style="margin-right: 10px;"><span style="color: #f26522;" class="fa fa-rss"></span> <a href="/Feed/RecentAdditions?format=rss">RSS</a></span> <span><span style="color: #f26522;" class="fa fa-rss"></span> <a href="/Feed/RecentAdditions?format=atom">Atom</a></span>
                                    </p>
                                </div>
                                <div class="col-md-4">
                                    <h3 style="font-family: 'Oswald'; padding-bottom: 20px; margin: 0;">
                                    Sitemap
                                    </h3>
                                    <h4>
                                        <a href="/AboutUs/Home">About Us</a>
                                    </h4>
                                    <h4>
                                        <a href="/News/Home">News</a>
                                    </h4>
                                    <h4>
                                        <a href="/News/RecentAdditions">Recent Additions</a>
                                    </h4>
                                    <h4>
                                        <a href="/Museum/Home">Museum</a>
                                    </h4>
                                    <h4>
                                        <a href="/Education/Home">Education</a>
                                    </h4>
                                    <h4>
                                        <a href="/Competition/Home">Competitions</a>
                                    </h4>
                                    <h4>
                                        <a href="/Store/Home">Store</a>
                                    </h4>
                                    <h4>
                                        <a href="/MyARC/Home/Index">My ARC</a>
                                    </h4>
                                </div>
                                <div class="col-md-4">
                                    <h3 style="font-family: 'Oswald'; padding-bottom: 20px; margin: 0;">Contact Us</h3>
                                    <address>
                                        Art Renewal Center<br />
                                        100 Markley Street<br />
                                        Port Reading, NJ 07064<br /><br />
                                        <a href="mailto:feedback@artrenewal.org">feedback@artrenewal.org</a><br /><br />
                                        (+1) 732-636-2060 ext 619
                                    </address>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" style="margin-top: 20px;">
                        <div class="col-md-6">
                            <p>
                                &copy; 1999-{new Date().getFullYear()} Art Renewal Center. All rights reserved.
                            </p>
                        </div>
                        <div class="col-md-6" style="font-size: 2em; text-align: right;">
                            <a class="hvr-float" href="https://www.facebook.com/Art-Renewal-Center-247051022002707"><img style="height: 1.25em;" src="/Images/icons/facebook.svg" /></a> <a class="hvr-float" href="https://twitter.com/ArtRenewalCtr"><img style="height: 1.25em;" src="/Images/icons/twitter.svg" /></a> <a class="hvr-float" href="https://www.instagram.com/artrenewalcenter/?hl=en"><img style="height: 1.25em;" src="/Images/icons/instagram.svg" /></a> <a class="hvr-float" href="https://plus.google.com/103209837780530093850"><img style="height: 1.25em;" src="/Images/icons/google-plus.svg" /></a>
                        </div>
                    </div>
                    <Img src="positivessl_trust_seal_md_167x42.png" />
                </div>
            </footer>
        </div>
    );
};