import { Component, splitProps } from "solid-js";
import { toQueryString } from "../../API";

export const A : Component<any> = (props) => {
    const [local, others] = splitProps(props, ["href", "parameters"]);
    let href = local.href;
    if (local.parameters) {
        href += "?" + toQueryString(local.parameters);
    }
    const onClick = (e: Event ) => {
        e.preventDefault();
        window.location.href = href;
    };
    return (<a href={href} {...others} onClick={onClick}>{props.children}</a>);
}

export const NavDropdownItem : Component<any> = (props) => {
    const [local, others] = splitProps(props, ["href", "parameters"]);
    let href = local.href;
    if (local.parameters) {
        href += "?" + toQueryString(local.parameters);
    }
    const onClick = (e: Event ) => {
        e.preventDefault();
        window.location.href = href;
    };
    return (<a href={href} {...others} class="dropdown-item" data-rr-ui-dropdown-item="" onClick={onClick}>{props.children}</a>);
}