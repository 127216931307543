import { Show } from "solid-js";
import { SetStoreFunction, createStore } from "solid-js/store";

type ErrorMessageProps = {
  error: string;
}
export const ErrorMessage = (props: ErrorMessageProps) => <Show when={!!props.error}><div><span class="error-message">{props.error}</span></div></Show>;

type FormSubmitHandler = (form: HTMLFormElement) => void;
type ValidatorMethod = (el: HTMLInputElement) => string;
type ValidatorMethods = ValidatorMethod[];
type ErrorRecord = Record<string, string>;
type TErrorRecord<TModel> = Record<keyof TModel, string>;
type FieldRecord = Record<string, ElementValidator>;
type ElementValidator = {element: HTMLInputElement, validators: ValidatorMethods }
export type FormValidator = { validate: (input: HTMLElement, accessor: any) => void, formSubmit: (form: HTMLFormElement, accessor: any) => void, errors: ErrorRecord };
export type TFormValidator<TModel> = {
  validate: (input: HTMLElement, accessor: any) => void,
  formSubmit: (form: HTMLFormElement, accessor: any) => void,
  errors: TErrorRecord<TModel>,
  setErrors: SetStoreFunction<TErrorRecord<TModel>>
};

function checkValid({ element, validators = [] } : ElementValidator, setErrors: SetStoreFunction<ErrorRecord>, errorClass: string) {
  return async () => {
    /***
     * Check the standard HTML validators first.
     */
    element.setCustomValidity("");
    element.checkValidity();
    let message = element.validationMessage;
    if (!message) {
      /***
       * Then check the custom validators.
       */
      for (const validator of validators) {
        if (validator) {
          const text = await validator(element);
          if (text) {
            /***
             * Set the error message, then quit early as we want only one message at a time per element.
             */
            element.setCustomValidity(text);
            break;
          }
        }
      }
      message = element.validationMessage;
    }
    if (message) {
      /***
       * Activate the error class on the element.
       */
      errorClass && element.classList.toggle(errorClass, true);
      /***
       * Set the message on the associated error store using the 'name' attribute on the element.
       */
      setErrors({ [element.name]: message });
    }
    //console.log("checkValid", { element, validators, message });
  };
}

export function useForm({ errorClass }: { errorClass: string }) {
  const [errors, setErrors] = createStore<ErrorRecord>({});
  const fields: FieldRecord = { };
  const validate = (input: HTMLInputElement, accessor: any) => {
    const validators: ValidatorMethods = accessor() || [];
    let config: ElementValidator;
    fields[input.name] = config = {
      element: input,
      validators
    };
    input.onblur = checkValid(config, setErrors, errorClass);
    input.oninput = () => {
      /**
       * If no error field matching the 'name' attribute is found then quit.
       */
      if (!errors[input.name]) {
        return;
      }
      /**
       * Clear the error message.
       */
      setErrors({ [input.name]: undefined });
      /**
       * Clear the error class.
       */
      errorClass && input.classList.toggle(errorClass, false);
      //console.log("useForm", { input})
    };
  };
  
  const formSubmit = (form: HTMLFormElement, accessor: any) => {
    
    const userSubmitHandler: FormSubmitHandler = accessor() || (() => {});
    form.setAttribute("novalidate", "");
    /**
     * Handle the submit event
     */
    form.onsubmit = async (e: Event) => {
      e.preventDefault();
      let hasErrors = false;
      for (const k in fields) {
        const field = fields[k];
        await checkValid(field, setErrors, errorClass)();
        if (!hasErrors && field.element.validationMessage) {
          /**
           * Focus on the first erroring element found and quit early.
           */
          field.element.focus();
          hasErrors = true;
        }
      }
      console.log("onsubmit", { hasErrors, form, fields, errors })
      /**
       * If there are no errors only then invoke the user's submit handler.
       */
      !hasErrors && userSubmitHandler(form);
    };
  };
  return { validate, formSubmit, errors, setErrors };
}