import { Component, splitProps } from "solid-js";
import { cleanImagePath } from "../../library/common";

export enum Mode {
    crop = "crop",
    fit = "fit"
}

const heightCache: Record<number, number> = {};
const widthCache: Record<number, number> = {};
const getHeight = (maxWidth: number, width: number, height: number) => {
    const ratio = maxWidth / width;
    const h = Math.floor(height * ratio);
    return h;
};
const getWidth = (maxHeight: number, width: number, height: number) => {
    const ratio = maxHeight / height;
    const w = Math.floor(width * ratio);
    return w;
};

export const Img : Component<any> = (props) => {
    const [local, others] = splitProps(props, ["src", "mode", "width", "height", "format", "maxWidth", "maxHeight",  "naturalHeight", "naturalWidth", "style"]);
    let src = local.src;
    let style = local.style || {};
    let imageflow: string[] = [];
    if (local.mode) imageflow.push("mode=" + local.mode);
    if (local.maxWidth && local.naturalWidth && local.naturalHeight) {
        const height = getHeight(local.maxWidth, local.naturalWidth, local.naturalHeight);
        imageflow.push("width=" + local.maxWidth);
        imageflow.push("height=" + height);
        style.width = local.maxWidth + "px";
        style.height = height + "px";
    } else if (local.maxHeight && local.naturalWidth && local.naturalHeight) {
        const width = getWidth(local.maxHeight, local.naturalWidth, local.naturalHeight);
        imageflow.push("height=" + local.maxHeight);
        imageflow.push("width=" + width);
        style.height = local.maxHeight + "px";
        style.width = width + "px";
    } else {
        if (local.width) imageflow.push("width=" + local.width);
        if (local.height) imageflow.push("height=" + local.height);
    }
    if (local.format) imageflow.push("format=" + local.format);
    if (imageflow.length) src += "?" + imageflow.join("&");
    return (<img src={cleanImagePath(src)} style={style} {...others}></img>);
}