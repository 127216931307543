import { Show, onMount } from "solid-js";
import { CMS } from "../components/cms/cms";
import { Progress } from "../components/progress";
import { loadJurorsCms } from "../app";
import { createStore } from "solid-js/store";
import { ScrollToTarget } from "../components/scroll-to-target";

type State = {
    isCMSReady: boolean;
}
export function JurorsPage() {
    const [state, setState] = createStore<State>({ isCMSReady: false });
    onMount(async() => {
        await loadJurorsCms();
        setState({ isCMSReady: true });
    });
    return (
        <>
            <Show when={state.isCMSReady}>
                <ScrollToTarget selector=".juror-link">
                    <div class="article">
                        <div class="container">
                            <Progress step={0} />
                            <h4 class="salon-heading-xxl m-t-xxl" style="text-align: center;">
                                <CMS text id="label::jurors">Jurors</CMS>
                            </h4>
                            <CMS id="salon-application/jurors::description">
                                In addition to our category jurors listed below, there are numerous additional jurors participating with our partnering awards. To view these additional jurors, please read the award descriptions on the awards page.
                            </CMS>
                            <div class="juror-grid">
                                <CMS id="salon-application/jurors::juror-listing"></CMS>
                            </div>
                            <h4 class="salon-heading-xxl m-t-xxl" style="text-align: center;">
                                <CMS text id="label::arc-jurors">ARC Jurors</CMS>
                            </h4>
                            <CMS id="jurors::fred-ross"></CMS>
                            <CMS id="jurors::kara-lysandra-ross"></CMS>
                            <CMS id="jurors::vern-swanson"></CMS>
                            <CMS id="jurors::michael-john-angel"></CMS>
                            <CMS id="jurors::juliette-aristides"></CMS>
                            <CMS id="jurors::daniel-graves"></CMS>
                            <CMS id="jurors::anthony-waichulis"></CMS>
                            <CMS id="jurors::patrick-wilshire"></CMS>
                            <CMS id="jurors::eric-rhoads"></CMS>
                            <h4 class="salon-heading-xxl m-t-xxl" style="text-align: center;">
                                <CMS text id="label::guest-jurors">Guest Jurors</CMS>
                            </h4>
                            <CMS id="jurors::maudie-brady"></CMS>
                            <CMS id="jurors::renee-bemis"></CMS>
                            <CMS id="jurors::arantzazu-martinez"></CMS>
                            <CMS id="jurors::xu-mangyao"></CMS>
                            <CMS id="jurors::leng-jun"></CMS>
                            <CMS id="jurors::qin-ming"></CMS>
                            <CMS id="jurors::bridget-macavoy"></CMS>
                            <CMS id="jurors::bai-yuping"></CMS>
                            <CMS id="jurors::thomas-kegler"></CMS>
                            <CMS id="jurors::richard-macdonald"></CMS>
                            <CMS id="jurors::julie-bell"></CMS>
                            <CMS id="jurors::mario-andres-robinson"></CMS>
                            <CMS id="jurors::jim-mcvicker"></CMS>
                            <CMS id="jurors::mary-pettis"></CMS>
                            <CMS id="jurors::annie-murphy-robinson"></CMS>
                        </div>
                    </div>
                </ScrollToTarget>
            </Show>
        </>
    )
}