import { Component, For, JSXElement, Show, createSignal } from "solid-js";
import { fetchGet } from "../../../library/common";
import styles from "./index.module.css";

type Props = {
    cultureCode?: string;
}
type FlagProps = {
    code: string;
}
const Flag : Component<FlagProps> = (props) => <img class={styles["flag"]} src={"/solid/common/assets/images/flags/4x3/" + props.code + ".svg"} />;

type LanguageListItem = { CultureCode: string, Language: string; FlagCode?: string;  };
export const LanguageSelector : Component<Props> = (props) => {
    const [cultureCode, setCultureCode] = createSignal<string>(props.cultureCode || "en");
    const message: any = {
        "en": "This translation was provided by translation software and is for your convenience only. Please excuse any errors in translation. If you have any questions please contact Iian Neill at iian.d.neill@gmail.org",
        "ja": "この翻訳は翻訳ソフトウェアによって提供されており、便宜のみを目的としています。 翻訳の誤りはご容赦ください。 ご質問がございましたら、Iian Neill（iian.d.neill@gmail.org）までご連絡ください。",
        "zh": "此翻译是由翻译软件提供的，仅为您提供方便。 请原谅翻译中的任何错误。 如有任何疑问，请通过iian.d.neill@gmail.org与Iian Neill联系。",
        "es": "Esta traducción fue proporcionada por un software de traducción y es solo para su conveniencia. Por favor, disculpe cualquier error en la traducción. Si tiene alguna pregunta, comuníquese con Iian Neill en iian.d.neill@gmail.org",
        "de": "Diese Übersetzung wurde von einer Übersetzungssoftware bereitgestellt und dient nur Ihrer Bequemlichkeit. Bitte entschuldigen Sie eventuelle Fehler in der Übersetzung. Bei Fragen wenden Sie sich bitte an Iian Neill unter iian.d.neill@gmail.org",
        "id": "Terjemahan ini disediakan oleh perangkat lunak terjemahan dan hanya untuk kenyamanan Anda. Mohon maaf jika ada kesalahan dalam terjemahan. Jika Anda memiliki pertanyaan, silakan hubungi Iian Neill di iian.d.neill@gmail.org",
        "it": "Questa traduzione è stata fornita dal software di traduzione ed è solo per tua comodità. Si prega di scusare eventuali errori di traduzione. In caso di domande, contattare Iian Neill all'indirizzo iian.d.neill@gmail.org",
        "ru": "Этот перевод предоставлен программным обеспечением для перевода и предназначен только для вашего удобства. Прошу прощения за любые ошибки в переводе. Если у вас есть какие-либо вопросы, пожалуйста, свяжитесь с Iian Neill по адресу iian.d.neill@gmail.org",
        "fr": "Cette traduction a été fournie par un logiciel de traduction et est uniquement pour votre commodité. Veuillez excuser toute erreur de traduction. Si vous avez des questions, veuillez contacter Iian Neill à iian.d.neill@gmail.org"
    };
    const updateCulture = async (lang: string) => {
        const response = await fetchGet("/Home/SetCulture", { lang });
        const json = await response.json();
        window.location.reload();
    };
    const languageChanged = async (e: Event) => {
        setCultureCode((e.currentTarget as HTMLSelectElement).value);
        const lang = cultureCode();
        const text = message[lang] ? message[lang] + "\r\r" + message.en : message.en;
        if (lang == "en" || confirm(text)) {
            await updateCulture(lang);
        }
    };
    const languages: LanguageListItem[] = [
        { Language: "English", CultureCode: "en", FlagCode: "us" },
        { Language: "中国人", CultureCode: "zh", FlagCode: "cn" },
        { Language: "Indonesia", CultureCode: "id" },
        { Language: "русский", CultureCode: "ru" },
        { Language: "Italiano", CultureCode: "it" },
        { Language: "Français", CultureCode: "fr" },
        { Language: "Deutsch", CultureCode: "de" },
        { Language: "Español", CultureCode: "es" },
        { Language: "日本語", CultureCode: "ja", FlagCode: "jp" }
    ];
    const selected = () => languages.find(x => x.CultureCode == cultureCode()) as LanguageListItem;
    return (
        <>
            <div class={styles["image-dropdown"]}>
                <Show when={cultureCode()}>
                    <div class={styles["selected-list-item"]}>
                        <Flag code={selected()?.FlagCode || selected()?.CultureCode} /> {selected().Language}
                    </div>
                </Show>
                <div class={styles["list-items-container"]}>
                    <For each={languages}>{(opt, i) =>
                        <div class={styles["list-item"]}>
                            <input
                                checked={cultureCode() == opt.CultureCode}
                                onInput={(e) => languageChanged(e)}
                                type="radio"
                                id={"line" + i()}
                                name="CultureCode"
                                value={opt.CultureCode}
                            /><label for={"line" + i()}><Flag code={opt.FlagCode || opt.CultureCode} /> {opt.Language}</label>    
                        </div>
                    }</For>
                </div>
            </div>
        </>
    );
};