import { Component, Show, onMount } from "solid-js";
import { createStore } from "solid-js/store";
import { loadCms } from "../app";
import { CMS } from "./cms/cms";

type State = {
    isContentReady: boolean;
}
export const PrepareImageText : Component = () => {
    const [state, setState] = createStore<State>({ isContentReady: false });
    onMount(async () => {
        await loadCms([
            "salon-application::how-to-prepare-images"
        ]);
        setState({ isContentReady: true });
    });
    return (
        <div>
            <Show when={state.isContentReady}>
                <div class="row">
                    <div class="col-md-12">
                        <CMS id="salon-application::how-to-prepare-images">
                            <p>
                                Images should be in JPEG or PNG format and between 1MB and 15MBs each in size.
                            </p>
                            <h3>
                                How to Prepare Images
                            </h3>
                            <p>
                                File Format: Images must be in JPEG or PNG file format (with a .jpg or .png extension). Save the images at maximum quality with as little compression as possible. The file name does not matter as long as it ends in either ".jpg", ".jpeg" or ".png".
                            </p>
                            <h3>
                                Resolution and Orientation
                            </h3>
                            <p>
                                Your images should be around 2400 pixels in width (at least 2000 pixels) (for landscape oriented works) or around 2400 pixels (at least 2000 pixels) in height (for portrait oriented works) and they should be "right side up" and without any borders or titles included. Example: A digital image measuring 8 x 10 inches at 300 DPI would be 2400 pixels by 3000 pixels. This will allow us to print your artwork at the best level of quality in the ARC Salon Publication and be a sufficient size for our jurors to properly adjudicate. Please make sure your photos are not blurry and keep the total size of your files less than 15 megabytes and a minimum of 1 megabyte.
                            </p>
                        </CMS>
                    </div>
                </div>
            </Show>
        </div>
    );
}