import { A } from "@solidjs/router";
import { MainSlider } from "../components/main-slider";
import { Img } from "../components/universal/image";

export const LogoSlider = () =>
    {
        return (
            <>
                <div class="logo-container">
                    <div class="logo-cell">
                        <a href="/">
                            <img
                                class="img-responsive"
                                src="https://www.artrenewal.org/secureimages/ContentSection/3/Images/859f2268-1cef-43d6-bec9-35d3c3a68038.jpg?w=951&h=303&mode=crop"
                            />
                        </a>
                    </div>
                    <div class="slider-cell">
                        <div style="background-color: #333; color: darkgoldenrod; ">
                            <div class="slick" style="height: 100%;">
                                <div class="slide">
                                    <figure>
                                        <A href="/">
                                            <Img
                                                src="/Content/209/7ec19892-9968-4fb0-983f-a8870398b300.jpg"
                                                width={951} height={303} mode="crop"
                                                style="width: 951px; height: 303px;"
                                                class="img-responsive"
                                            />
                                        </A>
                                        <figcaption>
                                            
                                        </figcaption>
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div class="logo-container">
                    <div class="logo-cell">
                        <a href="/">
                            <Img class="img-responsive" src={"/Media/Elements/arc-logo4.jpg"} w={951} h={303} mode="crop" />
                        </a>
                    </div>
                    <div class="slider-cell">
                        <MainSlider />
                    </div>
                </div> */}
            </>
        );
    };