import { Component, JSXElement, onCleanup, onMount } from "solid-js"

type Props = {
    selector: string;
    nextElementSiblingOffset?: number;
    children: JSXElement;
}
export const ScrollToTarget : Component<Props> = (props) => {
    let links: NodeListOf<HTMLElement>;
    const getLinks = () => document.querySelectorAll(props.selector) as NodeListOf<HTMLElement>;
    const scrollToTarget = (e: MouseEvent) => {
        if (!e) return;
        const aName = ((e.target as HTMLElement).getAttribute("data-target") as string)?.replace("#", "");
        if (!aName) {
            console.log("e.target doesn't have an attribute named 'data-target'; or it contains no value.", { e });
            return;
        }
        const link = document.querySelector(`a[name='${aName}']`);
        if (!link) {
            console.log(`Couldn't find an <a/> element with a 'name' matching "${aName}".`, { aName });
            return;
        }
        const parent = link?.parentElement;
        if (!parent) {
            console.log(`<a/> element doesn't have a parent.`, { link });
            return;
        }
        const offset = props.nextElementSiblingOffset || 2;
        let target = parent;        
        for (let i = 1; i <= offset; i++) target = target?.nextElementSibling as HTMLElement;
        const destination = target || parent;
        destination.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    };
    const setup = () => { links = getLinks(); links.forEach(el => el.addEventListener('click', scrollToTarget)) };
    onMount(() => setup());
    onCleanup(() => links.forEach(el => el.removeEventListener('click', scrollToTarget)));
    return (
        <>
            {props.children}
        </>
    )
}