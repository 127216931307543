export const cleanImagePath = (filepath: string) => {
    if (!filepath) return filepath;
    filepath = filepath.replace(new RegExp('~', 'g'), '');
    filepath = filepath.replace(new RegExp(':', 'g'), '_');
    filepath = filepath.replace(new RegExp('//', 'g'), '/');
    filepath = filepath.replace(new RegExp('\\\\', 'g'), '/');
    var url = encodeURI("/secureimages" + filepath);
    return url;
};
const cache: any = {};
export const clearCache = (key: string) => cache[key] = null;
export const getOrSet = (key: string, getter: () => any) =>{
    if (!!cache[key]) {
        return cache[key];
    }
    const value = getter();
    cache[key] = value;
    return value;
};
export const setCache = (key: string, value: any) => {
    cache[key] = value;
};
export const getOrSetAsync = async (key: string, getter: () => any, options?: { forceRefresh: boolean }) =>{
    if (!options?.forceRefresh) {
        if (!!cache[key]) {
            return cache[key];
        }
    }
    const value = await getter();
    cache[key] = value;
    return value;
};
export const getPageRange = (page: number, rangeSize: number, maxPage: number) => {
    var start = getPageBlockStart(page, rangeSize);
    var end = start + rangeSize - 1;
    if (end > maxPage) {
        end = maxPage;
    }
    var pages = [];
    for (var i = start; i <= end; i++) {
        pages.push(i);
    }
    return pages;
};
export const getPageBlockStart = (pageIndex: number, pageBlockSize: number) => {
    var pageBlock = Math.floor(pageIndex / pageBlockSize) + 1;
    var pageBlockStart = ((pageBlock - 1) * pageBlockSize) + 1;
    return pageBlockStart;
}
export const toQueryString = (data: {}): string =>{
    let _data = {};
    for (let key  in data) {
        let value = data[key];
        if (value === null || value === "null") {
            continue;
        }
        _data[key] = value;
    }
    const queryString = new URLSearchParams(_data);
    return queryString.toString();
}