import { Accessor, Component, Show, onMount } from "solid-js"
import { createStore } from "solid-js/store";
import { CMS } from "./cms/cms";
import { loadCms } from "../app";

type Model = {
    cataloguesOrdered: string;
}
type Props = {
    catalogues: Accessor<string>;
    onChange: (ordered: string) => Promise<string|undefined>;
}
type State = {
    isContentReady: boolean;
}
export const CatalogueOrders: Component<Props> = (props) => {
    const [state, setState] = createStore<State>({ isContentReady: false });
    const [model, setModel] = createStore<Model>({
        cataloguesOrdered: props.catalogues()
    } as Model);
    const onSubmit = (e: Event) => {
        e.preventDefault();
        props.onChange(model.cataloguesOrdered);
    }
    onMount(async () => {
        await loadCms([
            "salon-application/checkout::cost-of-first-catalogue-included",
            "salon-application/checkout::preorder-copies",
            "button::update"
        ]);
        setState({ isContentReady: true });
    })
    return (
        <div>
            <Show when={state.isContentReady}>
                <form onSubmit={onSubmit}>
                    <p style="clear:both;">
                        <CMS id="salon-application/checkout::cost-of-first-catalogue-included">
                            <b>Note:</b> the first publication ordered is included in the cost of the entry fee (for non-student entrants), not including the shipping. If you would prefer not to receive any publications, set the amount to zero.                        
                        </CMS>
                    </p>
                    <div style="float: left;">
                        <CMS text id="salon-application/checkout::preorder-copies">Pre-order copy/copies of the ARC Salon publication</CMS>
                        <input
                            type="number"
                            class="form-control additional-catalogues-ordered"
                            style="margin-top: -0.25em; width: 65px;"
                            min={0} max={2}
                            value={model.cataloguesOrdered}
                            onInput={(e) => setModel("cataloguesOrdered", e.currentTarget.value)}
                        /> 
                    </div>
                    <button style="margin-left: 20px; margin-top: -15px;" class="btn btn-primary" type="submit">
                        <CMS id="button::update">Update</CMS>
                    </button>
                </form>
            </Show>
        </div>
    )
}