import { createContext, useContext } from "solid-js";
import { SetStoreFunction } from "solid-js/store";
const CMSContext = createContext();

export type CMSBlock = {
  found: boolean;
  item: any;
}
export type CMSGetter = Record<string,CMSBlock>;
export type CMSSetter = SetStoreFunction<Record<string,CMSBlock>>;
type Props = {
  cms: CMSGetter;
  setCms: CMSSetter;
  children: any;
};
export type CMSProviderType = {
  cms: CMSGetter;
  setCms: CMSSetter;
}
export function CMSProvider(props: Props) {
   return (
    <CMSContext.Provider value={{ cms: props.cms, setCms: props.setCms }}>
      {props.children}
    </CMSContext.Provider>
  );
}

export function useCMS() { return useContext(CMSContext); }