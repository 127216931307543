import { Link } from "@solidjs/router";
import { Show, For, Component, createSignal, Accessor, createEffect, onMount } from "solid-js";
import { Img } from "./universal/image";
import { ListItem, SalonSubmission, SalonSubmissionBase, SalonSubmissionImage, UserSession } from "../types";
import { createStore } from "solid-js/store";
import { commitChange, fetchGet } from "../library/common";
import { Button, Form, InputGroup } from "solid-bootstrap";
import { RawHtml } from "./raw-html";
import toast from "solid-toast";
import { CMS } from "./cms/cms";
import { getText, loadCms } from "../app";

export type ArtworkProps = {
    session: UserSession;
    artwork: SalonSubmissionBase;
    resources: {
        canApply: boolean;
        categories: Accessor<ListItem[]>;
    };
    deleteClicked: (id: number) => void;
};

type State = {
    isContentReady: boolean;
}
export const Artwork : Component<ArtworkProps> = (props) => {
    const canApply: boolean = props.resources.canApply;
    const [state, setState] = createStore<State>({ isContentReady: false });
    const [artwork, setArtwork] = createStore<SalonSubmissionBase>(props.artwork);
    const [selectedCategoryId, setSelectedCategoryId] = createSignal<string>("");
    const [availableCategories, setAvailableCategories] = createSignal<ListItem[]>([]);
    const updateAvailableCategories = () => {
        const available = props.resources.categories()
            .filter((item) => !!item.Value)
            .filter((item) => !artwork.salonSubmissions.some(x => x.category.id == parseInt(item.Value)))
            ;
        setAvailableCategories([{ Text: "", Value: "" }, ...available]);
    };
    const deleteArtworkClicked = (artwork: SalonSubmissionBase) =>{
        console.log("deleteArtworkClicked", { artwork });
        if (confirm(getText("dialog::sure-you-wish-to-delete-this-entry", "Are you sure you wish to delete this entry?"))) {
            props.deleteClicked(artwork.id);
        }
    };
    const addCategoryClicked = async () => {
        if (!selectedCategoryId()) {
            return await commitError(
                getText("error::category/select-from-list", "You must first select a category from the list."),
                { SalonSubmissionBaseId: artwork.id }
            );
        }
        const model = {
            salonSubmissionBaseId: artwork.id,
            salonCategoryId: selectedCategoryId()
        };
        const response = await fetchGet("/SalonApplication/SalonSubmission/AddCategoryJson", model);
        const json = await response.json();
        if (!json.Success) {
            return await commitError(
                getText("error::category/unable-to-enter", "Unable to enter the work in the chosen category at this time."),
                model
            );
        }
        const newSubmission = json.Data;
        setArtwork("salonSubmissions", [...artwork.salonSubmissions, newSubmission]);
        updateAvailableCategories();
        const category = props.resources.categories().find(item => item.Value == selectedCategoryId());        
        toast.success(`Added "${props.artwork.title}" to the ${category?.Text} category`);
    }
    const commitError = async (error: string, data?: {}) => {
        await commitChange("17th-arc-salon-application/step-2/artwork/error", {
            Error: error,
            Data: data
        });
        toast.error(error);
    }
    const removeCategoryClicked = async (artworkCategory: SalonSubmission) => {
        const params = { salonSubmissionId: artworkCategory.id };
        const response = await fetchGet("/SalonApplication/SalonSubmission/DeleteCategoryJson", params);
        const json = await response.json();
        if (!json.Success) {
            return commitError(
                getText("error::category/unable-to-remove-artwork", "Unable to take the artwork out of the category at this time."),
                params
            );
        }
        const remaining = artwork.salonSubmissions.filter(x => x.id != artworkCategory.id);
        setArtwork("salonSubmissions", remaining);
        toast.success(`Removed "${props.artwork.title}" from the ${artworkCategory.category.name} category`);
    };
    const deleteImageClicked = async (artworkImage: SalonSubmissionImage) => {
        if (!confirm(getText("dialog::are-you-sure-you-wish-to-delete-image", "Are you sure you wish to delete this image?"))) {
            return;
        }
        const model = {
            salonSubmissionBaseId: artwork.id,
            newImageId: artworkImage.newImage.id
        };
        const response = await fetchGet("/SalonApplication/SalonSubmission/DeleteImageJson", model);
        const json = await response.json();
        console.log("deleteImageClicked", { json });
        if (!json.Success) return;
        const remaining = artwork.images.filter(img => img.id != artworkImage.id);
        setArtwork("images", remaining);
        toast.success(`Image "${artworkImage.newImage.url}" has been deleted.`);
    };
    const toKBs = (value: number) => Math.ceil(value / 1000);
    createEffect(() => props.resources.categories() && updateAvailableCategories());
    onMount(async () => {
        const ids=[
            "label::medium","label::purchase-award-nominated-price","label::size","label::completed-in","label::location","label::about-this-artwork",
            "label::categories","label::images",
            "salon-application/step-2::salon-artwork/file-details",
            "button::view","button::edit","button::delete","button::add",
            "button::remove", "label::added-on-date",
            "error::category/unable-to-enter","error::category/select-from-list",
            "dialog::sure-you-wish-to-delete-this-image","dialog::sure-you-wish-to-delete-this-entry",
            "error::category/unable-to-remove-artwork","error::category/unable-to-enter"
        ];
        await loadCms(ids);
        setState("isContentReady", true);
    });
    return (
        <Show when={state.isContentReady}>
            <div class="artwork-container">
                <div style="padding: 10px 20px; padding-bottom: 0; position: relative;">
                    <p style="font-size: 0.8em;">
                        <b>
                            <CMS data={{ date: artwork.createdDate }} id="label::added-on-date">{"Added on {date} PST"}</CMS>
                        </b>
                    </p>
                </div>
                <div style="padding: 0 20px;">
                    <h3>
                        {artwork.title}
                        <div style="float: right;">
                            <Button variant="primary" style="margin-right: 5px;" href={ "#/edit-artwork/" + artwork.id }><CMS id="button::edit">Edit</CMS></Button>
                            <Show when={canApply}>
                                <Button variant="outline-danger" onClick={(e) => { e.preventDefault(); deleteArtworkClicked(artwork); }}><CMS id="button::delete">Delete</CMS></Button>
                            </Show>
                        </div>
                    </h3>
                    <table class="artwork-details-table">
                        <thead>
                            <tr>
                                <td style="width: 150px;"></td>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr style={{ "border-top": "1px dotted #ccc"}}>
                                <td>
                                    <CMS id="label::medium" text>Medium</CMS>
                                </td>
                                <td>
                                    {artwork.medium}
                                </td>
                            </tr>
                            <tr style={{ "border-top": "1px dotted #ccc"}}>
                                <td>
                                    <CMS id="label::purchase-award-nominated-price" text>Purchase award nominated price</CMS>
                                </td>
                                <td>
                                    <Show when={artwork.purchaseAwardNominatedPrice != null}>
                                        <span>USD {artwork.purchaseAwardNominatedPrice}</span>
                                    </Show>
                                </td>
                            </tr>
                            <tr style={{ "border-top": "1px dotted #ccc"}}>
                                <td>
                                    <CMS id="label::size" text>Size</CMS>
                                </td>
                                <td>
                                    <div innerHTML={artwork.measurements || ""}></div>
                                </td>
                            </tr>
                            <tr style={{ "border-top": "1px dotted #ccc"}}>
                                <td>
                                    <CMS id="label::completed-in" text>Completed in</CMS>
                                </td>
                                <td>
                                    {artwork.year}
                                </td>
                            </tr>
                            <tr style={{ "border-top": "1px dotted #ccc"}}>
                                <td>
                                    <CMS id="label::location" text>Location</CMS>
                                </td>
                                <td>
                                    {artwork.location}
                                </td>
                            </tr>
                            <tr style={{ "border-top": "1px dotted #ccc"}}>
                                <td>
                                    <CMS id="label::about-this-artwork" text>About this artwork</CMS>
                                </td>
                                <td>
                                    <RawHtml value={artwork.comment} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div style="padding: 10px 20px; border-top: 1px solid #ccc;">
                    <h4>
                        <CMS id="label::categories" text>
                            Categories
                        </CMS>
                        <Show when={canApply && !props.session?.isHighSchoolStudent}>
                            <InputGroup class="mb-3">
                                <Form.Select value={selectedCategoryId()} onChange={(e) => setSelectedCategoryId(e.currentTarget.value)}>
                                    <For each={availableCategories()}>{(item) =><option value={item.Value}>{item.Text}</option>}</For>
                                </Form.Select>
                                <Button variant="primary" onClick={(e) => { e.preventDefault(); addCategoryClicked(); }}>Add</Button>
                            </InputGroup>                    
                        </Show>
                    </h4>
                    <div class="row">
                        <div class="col-md-12">
                            <For each={artwork.salonSubmissions}>{(submission, index) => 
                                <p>
                                    <span style="border-radius: 3px; margin-right: 3px; padding: 3px 6px; background-color: #ccc; color: #333;">
                                        {submission.category.name}
                                    </span>
                                    <Show when={canApply}>
                                        <Show when={index() > 0}>
                                            <Button variant="outline-danger" size="sm" onClick={(e) => { e.preventDefault(); removeCategoryClicked(submission); }}>
                                                <CMS id="button::remove" text>Remove</CMS>
                                            </Button>
                                        </Show>
                                    </Show>
                                </p>
                            }</For>
                        </div>
                    </div>
                </div>
                <div style="padding: 10px 20px; border-top: 1px solid #ccc;">
                    <h4>
                        <CMS id="label::images" text>Images</CMS>
                        <label style="float:right;">
                            <Button variant="primary" href={ "#/add-image-to-artwork/" + artwork.id }><CMS id="button::add" text>Add</CMS></Button>
                        </label>
                    </h4>
                </div>
                <div style="background-color: #000; color: #fff; padding: 10px;">
                    <div class="row">
                        <div class="col-md-12">
                            <For each={artwork.images}>{(artworkImage, index) =>
                                <div style="display: inline-block; width: 49%; margin-right: 3px; vertical-align: top;">
                                    <div onClick={(e) => { e.preventDefault(); alert((index()+1) + " clicked")}} style={{ "background-color": "goldenrod", "padding": "3px" }}>
                                        #{index()+1}
                                    </div>
                                    <div>
                                        <Link href={ "/edit-image/" + artworkImage.id }>
                                            <Img src={ artworkImage.newImage.url } mode="crop" w={300} format="jpg" style="width: 100%;" />
                                        </Link>
                                    </div>
                                    <div style="padding: 10px;">
                                        <div>
                                            <CMS id="salon-application/step-2::file-details"
                                                data={
                                                {
                                                    height: artworkImage.newImage.height,
                                                    width: artworkImage.newImage.width,
                                                    fileSize: toKBs(artworkImage.newImage.fileSizeBytes)
                                                }}>
                                                {"{height} x {width} pixels ({fileSize} KBs)"}
                                            </CMS>
                                        </div>
                                        <div style="margin-top: 10px;">
                                            <Link class="btn btn-sm btn-default" style="margin-right: 10px;" href={ "/view-image/" + artworkImage.newImage.id }>
                                                <CMS id="button::view" text>View</CMS>
                                            </Link>
                                            <Link class="btn btn-sm btn-primary" style="margin-right: 10px;" href={ "/edit-image/" + artworkImage.id }>
                                                <CMS id="button::edit" text>Edit</CMS>
                                            </Link>
                                            <a class="btn btn-sm btn-danger" onClick={(e) => { e.preventDefault(); deleteImageClicked(artworkImage); }} style="margin-right: 10px;">
                                                <CMS id="button::delete" text>Delete</CMS>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            }</For>
                        </div>
                    </div>
                </div>
            </div>
    </Show>
    );
}