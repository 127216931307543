import { Show, onMount } from "solid-js";
import { CMS } from "../components/cms/cms";
import { Progress } from "../components/progress";
import { loadAwardsCms, loadCms } from "../app";
import { createStore } from "solid-js/store";

type State = {
    isContentReady: boolean;
}
export function AwardsPage() {
    const [state, setState] = createStore<State>({ isContentReady: false });
    onMount(async() => {
        await loadAwardsCms();
        setState({ isContentReady: true });
    });
    return (
        <>
            <Show when={state.isContentReady}>
                <div class="article">
                    <div class="container">
                        <Progress step={0} />
                        <CMS id="salon-application/awards::page-body"></CMS>
                    </div>
                </div>
            </Show>
        </>
    )
}