import { createStore } from "solid-js/store";
import { ErrorMessage, useForm } from "../components/universal/validation";
import { For, Show, onMount } from "solid-js";
import { ListItem } from "../types";
import { Button } from "solid-bootstrap";
import { useNavigate, useRouteData } from "@solidjs/router";
import { Progress } from "../components/progress";
import { commitChange, fetchPost, scrollToTop } from "../library/common";
import { Required } from "../components/universal/required-icon";
import toast from "solid-toast";
import { CMS } from "../components/cms/cms";
import { getText, loadCreditCardCms } from "../app";

export type CreditCardModel = {
    entrant: {
        amountOwing: number;
        firstName: string;
        lastName: string;
    }
    address: {
        streetLine1: string;
        state: string;
        city: string;
        postalCode: string;
        countryId: string;
    }
    number: string;
    expiry: {
        month: string;
        year: string;
    };
    cvv: string;
}
export type CreditCardData = {
    resources: {
        countries: ListItem[];
    }
}
type State = {
    isSaving: boolean;
    isContentReady: boolean;
}
export function CreditCardPage() {
    const navigate = useNavigate();
    const months = [
        {Text:"01",Value:"1"},{Text:"02",Value:"2"},{Text:"03",Value:"3"},{Text:"04",Value:"4"},{Text:"05",Value:"5"},{Text:"06",Value:"6"},
        {Text:"07",Value:"7"},{Text:"08",Value:"8"},{Text:"09",Value:"9"},{Text:"10",Value:"10"},{Text:"11",Value:"11"},{Text:"12",Value:"12"}
    ];
    const years = [2023,2024,2025,2026,2027,2028,2029,2030,2031,2032,2033];
    const [data, setData] = createStore<CreditCardData>({
        resources: {
            countries: []
        }
    });
    const [state, setState] = createStore<State>({ isSaving: false, isContentReady: false });
    const [model, setModel] = createStore<CreditCardModel>({
        number: "",
        expiry: {
            month: "", year: ""
        },
        entrant: {
            amountOwing: 0, firstName: "", lastName: ""
        },
        address: {
            streetLine1: "",
            state: "",
            city: "",
            postalCode: "",
            countryId: ""
        },
        cvv: ""
    });
    const { formSubmit, validate, errors } = useForm({ errorClass: "error-message" });
    const commitError = async (error: string) => {
        await commitChange("17th-arc-salon-application/credit-card/error", {
            Error: error
        });
        toast.error(error);
    }
    const stripSpaces = (value: string) => value?.replace(/\s/g,'');
    const handleSubmit = async () => {
        try {
            if (state.isSaving) {
                toast.error("Payment is being submitted ... please wait.")
                return;
            }
            setState({ isSaving: true });
            const country = data.resources.countries.find(item => item.Value == model.address.countryId);
            const expirationDate = model.expiry.month + "/" + model.expiry.year;
            const _model  = {
                Number: stripSpaces(model.number),
                CCV: model.cvv,
                ExpiryYear: model.expiry.year,
                ExpiryMonth: model.expiry.month,
                ExpirationDate: expirationDate,

                FirstName: model.entrant.firstName,
                LastName: model.entrant.lastName,

                Address: model.address.streetLine1 || "",
                State: model.address.state || "",
                City: model.address.city || "",
                PostalCode: model.address.postalCode || "",
                Country: country?.Text || ""
            };
            const post = await fetchPost("/SalonApplication/Salon/PayCreditCardJson", _model);
            const json = await post.json();
            if (!json.Success) {
                return await commitError(
                    getText(
                        "salon-application/checkout::error/credit-card-payment-issue",
                        "There was an issue processing the credit card payment. Please contact the Webmaster, Iian Neill, at: iian.d.neill@gmail.com"
                    )
                );
            }
            toast.success("Your application fees have now been paid!");
            navigate("/payment/processed");
        } catch(ex) {
            console.log("handleSubmit", { ex });
            await commitError(getText(
                "salon-application/checkout::error/credit-card-payment-issue",
                "There was an issue processing the credit card payment. Please contact the Webmaster, Iian Neill, at: iian.d.neill@gmail.com"
            ));
        } finally {
            setState({ isSaving: false });
        }
    };
    const cancelClicked = (e: Event) => {
        e.preventDefault();
        toast.success(getText("salon-application/checkout::message/credit-card-payment-cancelled", "The credit card payment was cancelled at your request."));
        navigate("/step-3");
    }
    onMount(async () => {
        const routeData = await useRouteData() as any;
        setData({
            resources: {
                countries: routeData.resources.countries
            }
        });
        setModel({
            entrant: {
                amountOwing: routeData.entrant.amountOwing,
                firstName: routeData.entrant.firstName,
                lastName: routeData.entrant.lastName,
            },
            address: {
                streetLine1: routeData.address.streetLine1,
                state: routeData.address.state,
                city: routeData.address.city,
                postalCode: routeData.address.postalCode,
                countryId: routeData.address.countryId
            }            
        });
        await loadCreditCardCms();
        setState({ isContentReady: true });
        scrollToTop();
    });
    return (
        <div>
            <Progress step={3}></Progress>
            <Show when={state.isContentReady}>
                <h2>
                    <CMS id="label::pay-with-credit-card">Pay with credit card</CMS>
                </h2>
                <form use:formSubmit={handleSubmit}>
                    <div class="row">
                        <div class="col-md-12">
                            <h4>
                                <CMS data={{ "total-owing": model.entrant.amountOwing }} id="salon-application/credit-card::total-owing">{'Total owing: <span style="color: red;"><b>${total-owing}</b></span>'}</CMS>
                            </h4>
                        </div>
                    </div>
                    <h4 class="error" style="color: red;">
                        
                    </h4>
                    <div class="row">
                        <div class="col-md-4">
                            <label>
                                <Required /> <CMS id="label::credit-card-number">Credit Card Number</CMS>
                            </label>
                            <input 
                                type="text"
                                value={model.number}
                                name="number"
                                onInput={(e) => setModel("number", e.currentTarget.value)}
                                data-lpignore="true"
                                required
                                use:validate={[]}
                                class="form-control"
                            />
                            <ErrorMessage error={errors.number} />
                        </div>
                        <div class="col-md-2">
                            <label>
                                <Required /> <CMS id="label::expiry-mm-yy">Expiry (MM/YY)</CMS>
                            </label>
                            <div>
                                <span style="float: left;">
                                    <select style="width: 75px;"
                                        class="form-control"
                                        value={model.expiry.month}
                                        name="expiryMonth"
                                        data-lpignore="true"
                                        use:validate={[() => !model.expiry.month && !model.expiry.year ? "Please enter the month" : undefined]}
                                        onInput={(e) => setModel("expiry", "month", e.currentTarget.value)}>
                                        <For each={months}>{(month: ListItem) => <option value={month.Value}>{month.Text}</option>}</For>
                                    </select>
                                </span>
                                <span style="float:right;">
                                    <select style="width: 75px;"
                                        class="form-control"
                                        value={model.expiry.year}
                                        name="expiryYear"
                                        data-lpignore="true"
                                        use:validate={[() => !model.expiry.month && !model.expiry.year ? "Please enter the year" : undefined]}
                                        onInput={(e) => setModel("expiry", "year", e.currentTarget.value)}>
                                        <For each={years}>{(year) => <option value={year}>{year}</option>}</For>
                                    </select>
                                </span>
                            </div>
                            <div>
                                <ErrorMessage error={errors.expiryMonth} />
                                <ErrorMessage error={errors.expiryYear} />
                            </div>
                        </div>
                        <div class="col-md-2">
                            <label>
                                <Required /> <CMS id="label::ccv">CVV</CMS>
                            </label>
                            <input type="number"
                                style="width: 75px;"
                                class="form-control"
                                value={model.cvv}
                                data-lpignore="true"
                                required
                                min={1}
                                maxlength={4}
                                name="cvv"
                                use:validate={[]}
                                onInput={(e) => setModel("cvv", e.currentTarget.value)} />
                            <ErrorMessage error={errors.cvv} />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-2">
                            <label>
                                <CMS id="label::first-name">First name</CMS>
                            </label>
                            <input type="text"
                                maxLength={50}
                                name="firstName"
                                data-lpignore="true"
                                use:validate={[]}
                                class="form-control"
                                value={model.entrant.firstName}
                                onInput={(e) => setModel("entrant", "firstName", e.currentTarget.value)}
                            />
                            <ErrorMessage error={errors.firstName} />
                        </div>
                        <div class="col-md-2">
                            <label>
                                <CMS id="label::last-name">Last name</CMS>
                            </label>
                            <input type="text"
                                maxLength={50}
                                name="lastName"
                                data-lpignore="true"
                                use:validate={[]}
                                class="form-control"
                                value={model.entrant.lastName}
                                onInput={(e) => setModel("entrant", "lastName", e.currentTarget.value)}
                            />
                            <ErrorMessage error={errors.lastName} />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <label>
                                <CMS id="label::street">Street</CMS>
                            </label>
                            <input type="text"
                                class="form-control"
                                data-lpignore="true"
                                value={model.address.streetLine1}
                                onInput={(e) => setModel("address", "streetLine1", e.currentTarget.value)}
                            />
                        </div>
                        <div class="col-md-2">
                            <label>
                                <CMS id="label::city">City</CMS>
                            </label>
                            <input type="text"
                                class="form-control"
                                data-lpignore="true"
                                value={model.address.city}
                                onInput={(e) => setModel("address", "city", e.currentTarget.value)}
                            />
                        </div>
                        <div class="col-md-2">
                            <label>
                                <CMS id="label::state-province">State/Province</CMS>
                            </label>
                            <input type="text"
                                class="form-control"
                                data-lpignore="true"
                                value={model.address.state}
                                onInput={(e) => setModel("address", "state", e.currentTarget.value)}
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-2">
                            <label>
                                <CMS id="label::postal-code-zip">Post code (ZIP)</CMS>
                            </label>
                            <input type="text"
                                class="form-control"
                                data-lpignore="true"
                                value={model.address.postalCode}
                                onInput={(e) => setModel("address", "postalCode", e.currentTarget.value)}
                            />
                        </div>
                        <div class="col-md-4 col-md-offset-2">
                            <label>
                                <CMS id="label::country">Country</CMS>
                            </label>
                            <select class="form-control"
                                value={model.address.countryId} name="countryId" use:validate={[]} required data-lpignore="true"
                                onInput={(e) => setModel("address", "countryId", e.currentTarget.value)}>
                                <For each={data.resources.countries}>{(item: ListItem) => <option value={item.Value}>{item.Text}</option>}</For>
                            </select>
                            <ErrorMessage error={errors.countryId} />
                        </div>
                    </div>
                    <div class="row" style="margin-top: 20px;">
                        <div class="col-md-12">
                            <Button type="submit" classList={{ ["disabled"] : state.isSaving }} variant="primary" style="margin-right: 10px;">
                                <CMS id="button::pay-now">Pay Now</CMS>
                            </Button>
                            <button onClick={cancelClicked} class="btn btn-default">
                                <CMS id="button::cancel">Cancel</CMS>
                            </button>
                        </div>
                    </div>
                </form>
            </Show>
        </div>
    );
}