import { createStore } from "solid-js/store";
import { Component, For, JSXElement, Match, Show, Switch, createSignal, onMount } from "solid-js";
import _ from "underscore";
import { useNavigate, useRouteData } from "@solidjs/router";
import { commitChange, fetchPost, scrollToTop } from "../library/common";
import QuillEditor, { IQuillEditorWrapper } from "../components/universal/quill-editor";
import ImageCropper, { IImageCropperWrapper, ImageFileData } from "../components/universal/image-cropper";
import { EditArtworkData, EditArtworkModel, KeyOfType, ListItem, TranslatedListItem, UserSession } from "../types";
import { ErrorMessage, useForm } from "../components/universal/validation";
import { Progress } from "../components/progress";
import { Button, Form } from "solid-bootstrap";
import { PrepareImageText } from "../components/prepare-image-text";
import { Required } from "../components/universal/required-icon";
import toast from "solid-toast";
import { getText, loadCms } from "../app";
import { CMS } from "../components/cms/cms";
import { CharacterCount } from "../components/character-count";
import { Img } from "../components/universal/image";

type SalonEntrantImageData = {
    isPrimary: boolean;
    salonSubmissionBaseId: number;
    image: Blob;
};
type ImageFileDataListItem = {
    file: ImageFileData;
    order: number;
    id: number|null;
};
const Error = (props: any) => <Show when={!!props.field}><p><span class="error-message">{props.field}</span></p></Show>;
type State = {
    isContentReady: boolean;
    showFirstImageCropper: boolean;
    showSecondImageCropper: boolean;
    showThirdImageCropper: boolean;
}
type ButtonActiveState = {
    submit: boolean;
    cancel: boolean;
}

export function EditArtwork() {
    const [state, setState] = createStore<State>({
        isContentReady: false,
        showFirstImageCropper: false,
        showSecondImageCropper: false,
        showThirdImageCropper: false
    } as State);
    const [buttonActive, setButtonActive] = createStore<ButtonActiveState>({ submit: true, cancel: true } as ButtonActiveState);
    const [session, setSession] = createStore<UserSession>({ } as UserSession);
    const onlyNumbers = ({ value }: any) => /\D/.test(value) && "Please only enter digits or leave blank";
    const required = ({ value }: any) => !value && "Please enter a value";
    const maxLength = (el: HTMLInputElement, max: number) => {
        const len = el.value?.length;
        return len > max && `The field must be no longer than ${max} characters long. Please remove ${len - max} characters.`
    };
    const { formSubmit, validate, errors, setErrors } = useForm({
        errorClass: "error-input"
    });  
    const navigate = useNavigate();
    const [pageError, setPageError] = createSignal<string>("");
    const [artwork, setArtwork] = createStore<EditArtworkModel>({
        title:"",
        unitOfMeasurement: "",
        year: "",
        location: "",
        medium:"",
        height:"",
        width:"",
        depth:"",
        purchaseAwardNominatedPrice: "",
        framed: {}
    } as EditArtworkModel);
    const sessionGuid = (new Date()).getTime();
    // @ts-expect-error: Not assignable…
    const setArtworkWithEffect: SetStoreFunction<EditArtworkModel> = (...params: [any]) => {
        /**
         * https://stackoverflow.com/questions/78209469/how-to-create-a-wrapper-for-solidjss-setstore-in-typescript
         */
        setArtwork(...params);
        commitChange("17th-arc-salon-application/edit-artwork/model-change", {
            Session: sessionGuid, Id: artwork.id, Values: params
        }).then();
    };
    const [categories, setCategories] = createSignal<TranslatedListItem[]>([]);
    const [comment, setComment] = createSignal<string>("");
    const [unitOfMeasurements, setUnitOfMeasurements] = createSignal<ListItem[]>([{ Text: "inches", Value: "ins" },{ Text: "cms", Value: "cms" }]);
    const isAdding = () => !!artwork.id;
    const endYear = (new Date()).getFullYear();
    const startYear = endYear - 3;
    const [years, setYears] = createSignal<number[]>(_.range(startYear, endYear + 1));
    const isSculptureSelected = () => {
        const sculpture = categories().find(x => x.OriginalText == "Sculpture");
        if (!sculpture) {
            return false;
        }
        return artwork.categoryIds.some(cid => cid == sculpture.Value);
    };
    const onKeepFirstImageClicked = (e: Event) => {
        e.preventDefault();
        setState("showFirstImageCropper", false);
    };
    const onKeepSecondImageClicked = (e: Event) => {
        e.preventDefault();
        setState("showSecondImageCropper", false);
    };
    const onKeepThirdClicked = (e: Event) => {
        e.preventDefault();
        setState("showThirdImageCropper", false);
    }
    const uploadAnotherFirstImageClicked = (e: Event) =>{ 
        e.preventDefault();
        setState("showFirstImageCropper", true);
    }
    const uploadAnotherSecondImageClicked = (e: Event) =>{ 
        e.preventDefault();
        setState("showSecondImageCropper", true);
    }
    const uploadAnotherThirdImageClicked = (e: Event) =>{ 
        e.preventDefault();
        setState("showThirdImageCropper", true);
    }
    const getModel = () => {
        const comment = artwork.commentEditor?.getHtml();
        const categoryIds = artwork.categoryIds.filter(id => !!id)?.join(",") || "";
        return {
            id: artwork.id || "",
            title: artwork.title || "",
            medium: artwork.medium || "",
            width: artwork.width || "",
            height: artwork.height || "",
            depth: artwork.depth || "",
            isHighSchoolStudent: session.isHighSchoolStudent || false,
            categoryIds: categoryIds,
            studentAge: artwork.student?.age || "",
            firstName: artwork.student?.firstName || "",
            lastName: artwork.student?.lastName || "",
            unitOfMeasurement: artwork.unitOfMeasurement || "",
            purchaseAwardNominatedPrice: artwork.purchaseAwardNominatedPrice || "",
            location: artwork.location || "",
            year: artwork.year || "",
            comment: comment || ""
        };
    };
    const ModelImageCropper : Component<{ imageUrl: keyof KeyOfType<EditArtworkModel, string>; required?: boolean |(() => boolean); editor: keyof KeyOfType<EditArtworkModel, IImageCropperWrapper>; label?: string; }> = (props) =>
    <>
        <ImageCropper src={artwork[props.imageUrl]} required={props.required} maxHeight={6000} maxWidth={6000} setInstance={(wrapper) => setArtwork(props.editor, wrapper)} />
    </>;
    const ModelEditor : Component<{ field: keyof KeyOfType<EditArtworkModel, string>; editor: keyof KeyOfType<EditArtworkModel, IQuillEditorWrapper>; label?: string|(()=>JSXElement); }> = (props) =>
    <>
        <Show when={props.label}>
            <Show when={props.label instanceof String}>
                <label>
                    {props.label as string}
                </label>
            </Show>
            <Show when={!(props.label instanceof String)}>
                <label>
                    {(props.label as () => JSXElement)()}
                </label>
            </Show>
        </Show>
        <QuillEditor content={artwork[props.field] as string} setInstance={(editor) => setArtwork(props.editor, editor) } />
    </>;
    const getImageFiles = async () => {
        let images: ImageFileDataListItem[] = [];
        const first = state.showFirstImageCropper && await artwork.image1?.getImageFile();
        const second = state.showSecondImageCropper && await artwork.image2?.getImageFile();
        const third = state.showThirdImageCropper && await artwork.image3?.getImageFile();
        if (first) images.push({ file: first, order: 1, id: artwork.firstImage?.id || null });
        if (second) images.push({ file: second, order: 2, id: artwork.secondImage?.id || null });
        if (third) images.push({ file: third, order: 3, id: artwork.thirdImage?.id || null });
        return images;  
    };
    const updateSelectedCategoryIds = (id: string) => {
        const _selectedIds = artwork.categoryIds || [];
        const selectedIds = _selectedIds.some(cid => cid == id) ? _selectedIds.filter(cid => cid != id) : [..._selectedIds, id];
        setArtworkWithEffect({ categoryIds: selectedIds });
    };
    const requiresComment = () => artwork.commentEditor?.isEmpty();
    const requiresCategoryId = () => !session.isHighSchoolStudent && artwork.categoryIds?.length == 0;
    const requiresUnitOfMeasurement = () => !artwork.unitOfMeasurement;
    const countWords = (text: string) => {
        const re = new RegExp(/\b[^\s]+\b/, "g");
        let match;
        let count = 0;
        while ((match = re.exec(text)) != null) {
            count++;
        }
        return count;
    }
    const requiresFewerWords = () => {
        const html = artwork.commentEditor?.getHtml();
        const span = document.createElement("SPAN");
        span.innerHTML = html;
        const text = span.innerText;
        const count = countWords(text);
        return count <= 150;
    };
    const validateOtherFields = async () => {
        var isValid = true;
        setErrors("CategoryIds", "");
        setErrors("UnitOfMeasurement", "");
        if (requiresComment()) {
            await commitError(getText("salon-application/edit-artwork::comment-required-error", "You must provide a comment on the artwork."));
            isValid = false;
        }
        if (requiresCategoryId()) {
            await commitError(getText("salon-application/edit-artwork::must-select-category-error", "You must select at least one category."));
            isValid = false;
        }
        if (requiresUnitOfMeasurement()) {
            await commitError(getText("error::unit-of-measurement/please-select-a-unit", "Please select a unit of measurement."));
            isValid = false;
        }
        // if (requiresFewerWords()) {
        //     await commitError("You must enter no more than 150 words.");
        //     isValid = false;
        // }
        return isValid;
    };
    const validateImageCroppers = async () => {
        let isValid = true;
        const first = await artwork.image1?.getImageFile();
        if (first) {
            if (!first.isValid) {
                await commitError("There was one or more errors with the first image: " + first.errors.join('; '));
                isValid = false;
            }
        }
        const second = await artwork.image2?.getImageFile();
        if (second) {
            if (!second.isValid) {
                await commitError("There was one or more errors with the second image: " + second.errors.join('; '));
                isValid = false;
            }
        }
        const third = await artwork.image3?.getImageFile();
        if (third) {
            if (!third.isValid) {
                await commitError("There was one or more errors with the third image: " + third.errors.join('; '));
                isValid = false;
            }
        }
        return isValid;
    }
    const diagnose = async () => {
        console.log("diagnose", {
            artwork: artwork,
            model: getModel(),
            requiresCategoryId: requiresCategoryId(),
            requiresComment: requiresComment(),
            requiresUnitOfMeasurement: requiresUnitOfMeasurement(),
            isSculptureSelected: isSculptureSelected(),
            validateOtherFields: await validateOtherFields(),
            imageFiles: await getImageFiles()
        });
    };
    const diagnoseClicked = async () => await diagnose();
    const submitIfValid = async () => {
        setButtonActive("submit", false);
        const id = toast("Checking to see that the form is valid ...");
        await commitChange("17th-arc-salon-application/edit-artwork/form", getModel());
        const isValid = await validateOtherFields();
        toast.remove(id);
        if (!isValid) {
            return;
        }
        await save();
        setButtonActive("submit", true);
    };
    const commitError = async (error: string) => {
        enableButtons();
        const _model = getModel();
        await commitChange("17th-arc-salon-application/edit-artwork/error", {
            SalonSubmissionBaseId: _model.id,
            Error: error
        });
        toast.error(error);
    }
    const save = async () => {
        try {
            disableButtons();
            const model = getModel();
            if (requiresCategoryId()) {
                return await commitError("[006] " + getText("salon-application/edit-artwork::must-select-category-error", "You must select at least one category."));
            }
            if (requiresComment()) {
                return await commitError("[007] " + getText("salon-application/edit-artwork::comment-required-error", "You must provide a comment on the artwork."));
            }            
            const saveMessageId = toast(`Saving "${artwork.title}" details ...`);
            const res = await fetchPost("/SalonApplication/SalonSubmission/SaveJson", model);
            const json = await res.json();
            if (!json.Success) {
                toast.remove(saveMessageId);
                return await commitError("[002] " + getText("salon-application/edit-artwork::unable-to-save-artwork-details-error", "Unable to save the artwork details at this time."));
            }
            const id = json.Data.id;
            setArtworkWithEffect({ id });
            toast.success(
                getText("salon-application/edit-artwork::message/saved-artwork", `The artwork, "{title}", has been saved.`, { title: artwork.title }),
                { id: saveMessageId }
            );
            if (!state.showFirstImageCropper && !state.showSecondImageCropper && !state.showThirdImageCropper) {
                navigate("/step-2");
                return;
            }
            await saveImages(id, saveMessageId);
        } catch (ex) {
            await commitError("[008] There was an error saving the artwork. Please contact the Webmaster, Iian Neill, at: iian.d.neill@gmail.com");
        } finally {
            enableButtons();
        }
    };
    const toSalonEntrantImageData = (salonSubmissionBaseId: number, imageFile: ImageFileDataListItem) => {
        let model = {
            isPrimary: imageFile.order == 1,
            salonSubmissionBaseId: salonSubmissionBaseId,
            newImageId: imageFile.id || "",
            image: imageFile.file.image,
            filename: imageFile.file.filename
        } as SalonEntrantImageData;
        return model;
    };
    const enableButtons = () => setButtonActive({ submit: true, cancel: true });
    const disableButtons = () => setButtonActive({ submit: false, cancel: false });
    const getTotalArtworkImages = () => {
        let totalArtworkImages = 0;
        artwork.image1_url && totalArtworkImages++;
        artwork.image2_url && totalArtworkImages++;
        artwork.image3_url && totalArtworkImages++;
        return totalArtworkImages;
    }
    const saveImages = async (salonSubmissionBaseId: number, msgId: string) => {
        try {
            var isValid = await validateImageCroppers();
            if (!isValid) {
                return;
            }
            const allImageFiles = await getImageFiles();
            await commitChange("17th-arc-salon-application/edit-artwork/images", allImageFiles.map(img => ({
                NewImageId: img.id,
                Order: img.order,
                OriginalFileName: img.file?.filename,
                Errors: img.file?.errors,
                IsDirty: img.file?.isDirty,
                IsValid: img.file?.isValid
            })));
            const totalArtworkImages = getTotalArtworkImages();
            const newImages = allImageFiles.filter(f => f.file.isDirty);
            if (!newImages.length) {
                if (totalArtworkImages == 0) {
                    return await commitError("[004] " + getText("salon-application/edit-artwork::please-attach-at-least-one-image-error", "Please attach at least one image."));
                }
            }
            if (isSculptureSelected()) {
                if (totalArtworkImages < 2 && allImageFiles.length < 2) {
                    return await commitError("[005] " + getText("salon-application/edit-artwork::sculptures-require-at-least-two-images-error", "Sculptures require at least two images."));
                }
            }
            if (newImages.some(f => !f.file.isValid)) {
                return await commitError("[001] " + getText("salon-application/edit-artwork::an-image-is-invalid-format-error", "One of the image files is invalid; please check that the image format is either JPG or PNG."));
            }
            if (!newImages.length) {
                toast.remove(msgId);
                return navigate("/step-2");
            }
            toast.loading(
                getText("salon-application/edit-artwork::message/attaching-your-images", `Attaching your image(s) to "{title}" ...`, { title: artwork.title }),
                { id: msgId }
            );
            for await (let imageFile of newImages) {
                let model = toSalonEntrantImageData(salonSubmissionBaseId, imageFile);
                let response = await fetchPost("/SalonApplication/SalonSubmission/SavePhotoJson", model);
                let json = await response.json();
                if (!json.Success) {
                    return toast.error(
                        "[003] " + getText("salon-application/edit-artwork::unable-to-attach-image-error", "There was an error attaching an image to the application. The image file is: ") + imageFile.file.filename,
                        { id: msgId }
                    );
                }
            }
            const single = getText("salon-application/edit-artwork::message/one-image-uploaded", "One image was added to '{title}'.", {
                title: artwork.title
            });
            const plural = getText("salon-application/edit-artwork::message/multiple-images-uploaded", `{imageTotal} images were added to "{title}".`, {
                title: artwork.title, imageTotal: allImageFiles.length
            });
            toast.success(allImageFiles.length == 1 ? single : plural, {
                id: msgId
            });
            navigate("/step-2");
        } catch (ex) {
            toast.remove(msgId);
            toast.error("[009] There was an error uploading one or more of the images.");
        } finally {
            enableButtons();
        }
    };
    onMount(async () => {
        const routeData = await useRouteData() as EditArtworkData;
        const ids = [
            "label::artwork-details","message::required-fields",
            "salon-application/edit-submission::id/students-name",
            "label::first-name","label::last-name","label::title",
            "salon-application/edit-submission::medium/description",
            "label::category", "error::category/nominate-at-least-one",
            "salon-application/edit-submission::only-students-can-enter-student-category",
            "salon-application/edit-submission::purchase-award-nominated-price",
            "label::unit-of-measurement", "error::unit-of-measurement/please-select-a-unit",
            "label::height","label::width","label::depth",
            "salon-application::provide-frame-measurements",
            "form::height-framed","form::width-framed","label::student/age-at-completion",
            "label::completed-in","label::location",
            "salon-application::heading/upload-artwork-images",
            "label::image","label::second-image/optional","label::third-image/optional",
            "salon-application/edit-artwork::artwork-costs",
            "salon-application/edit-submission::about-this-artwork",
            "salon-application/edit-artwork::must-enter-at-least-150-words-error",
            "salon-application/edit-artwork::message/please-be-patient",
            "button::submit-this-artwork","button::save-changes","button::cancel",

            "salon-application/edit-artwork::comment-required-error",
            "salon-application/edit-artwork::must-select-category-error",
            "salon-application/edit-artwork::please-attach-at-least-one-image-error",
            "salon-application/edit-artwork::sculptures-require-at-least-two-images-error",
            "error::unit-of-measurement/please-select-a-unit",
            "salon-application/edit-artwork::an-image-is-invalid-format-error",
            "salon-application/edit-artwork::unable-to-save-artwork-details-error",
            "salon-application/edit-artwork::unable-to-attach-image-error",

            "salon-application/edit-submission::student-category-reminder",
            "salon-application/edit-submission::location/label"
        ];
        await loadCms(ids);
        setSession(routeData.session);
        
        setArtworkWithEffect(routeData.artwork);
        setCategories(routeData.categories);
        setComment(routeData.artwork.comment || "");
        if (routeData.artwork.firstImage?.url) {
            setArtworkWithEffect("image1_url", routeData.artwork.firstImage.url);
        } else {
            setState("showFirstImageCropper", true);
        }
        if (routeData.artwork.secondImage?.url) {
            setArtworkWithEffect("image2_url", routeData.artwork.secondImage.url);
        } else {
            setState("showSecondImageCropper", true);
        }
        if (routeData.artwork.thirdImage?.url) {
            setArtworkWithEffect("image3_url", routeData.artwork.thirdImage.url);
        } else {
            setState("showThirdImageCropper", true);
        }
        setState({ isContentReady: true });
        scrollToTop();
    });
    const firstArtworkPrice = 55;
    const successiveArtworkPrice = 30;
    const extraCategoryPrice = 20;
    return (
        <>
            <Show when={state.isContentReady}>
                <div>
                    <Progress step={2} />
                    <form use:formSubmit={submitIfValid}>
                        <div class="row">
                            <div class="col-md-6" style="padding-right: 20px;">
                                <div class="form-group">
                                    <h4 class="m-t-xxl m-b-lg" style="font-family: 'Oswald'; font-size: 3em;">
                                        <CMS id="label::artwork-details">Artwork details</CMS>
                                        <Show when={!!artwork.id}>
                                            <small># {artwork.id}</small>
                                        </Show>
                                    </h4>
                                    <p>
                                        <CMS id="message::required-fields">
                                            Fields that are marked with a <span class="fa fa-star" style="color: red;"></span> are required.
                                        </CMS>
                                    </p>
                                    <Show when={session.isHighSchoolStudent}>
                                        <div>
                                            <hr/>
                                            <div style="margin-bottom: 40px;">
                                                <CMS id="salon-application/edit-submission::description/students-name">
                                                    <label>
                                                        Student's name
                                                    </label>
                                                    <p>
                                                        If you are a teacher entering a student on their behalf you can provide their name here.
                                                    </p>
                                                </CMS>
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <label>
                                                            <CMS id="label::first-name" text>First name</CMS>
                                                        </label>
                                                        <input type="text"
                                                            value={artwork.student?.firstName}
                                                            name="student.firstName"
                                                            class="form-control"
                                                            onChange={(e) => setArtworkWithEffect("student", "firstName", e.currentTarget.value)}
                                                            ></input>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <label>
                                                            <CMS id="label::last-name" text>Last name</CMS>
                                                        </label>
                                                        <input type="text"
                                                            value={artwork.student?.lastName}
                                                            name="lastName"
                                                            class="form-control"
                                                            onChange={(e) => setArtworkWithEffect("student", "lastName", e.currentTarget.value)}
                                                            ></input>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Show>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <label>
                                                <Required /> <CMS id="label::title" text>Title</CMS>
                                            </label>
                                            <input
                                                type="text"
                                                value={artwork.title}
                                                name="title"
                                                class="form-control"
                                                onInput={(e) => setArtwork("title", e.currentTarget.value)}
                                                onChange={(e) => setArtworkWithEffect("title", e.currentTarget.value)}
                                                use:validate={[
                                                    required,
                                                    ({ value }: any) => maxLength(value, 100)
                                                ]}
                                            ></input>
                                            <p>
                                                <CharacterCount value={artwork.title} max={100} />
                                            </p>
                                            <ErrorMessage error={errors.title}></ErrorMessage>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <label>
                                                <Required /> <CMS id="label::medium" text>Medium</CMS>
                                            </label>
                                            <CMS id="salon-application/edit-submission::medium/description">
                                                <p>
                                                    * If this is not a sculpture, please make sure to include what the medium is on. Examples: write "Oil on canvas", "Oil on panel", "Graphite on paper", etc. Do not just write "Oil" or "Graphite".
                                                </p>
                                            </CMS>
                                            <input type="text"
                                                value={artwork.medium}
                                                name="medium"
                                                class="form-control"
                                                placeholder="E.g., Oil on canvas"
                                                onInput={(e) => setArtwork("medium", e.currentTarget.value)}
                                                onChange={(e) => setArtworkWithEffect("medium", e.currentTarget.value)}
                                                use:validate={[
                                                    required,
                                                    (el: any) => maxLength(el, 50)
                                                ]}
                                            ></input>
                                            <p>
                                                <CharacterCount value={artwork.medium} max={50} />
                                            </p>
                                            <ErrorMessage error={errors.medium}></ErrorMessage>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row" style="margin-top: 20px;">
                                        <div class="col-md-12">
                                            <label>
                                                <Required /> <CMS id="label::category" text>Category</CMS>
                                            </label>
                                            <Show when={!session.isHighSchoolStudent}>
                                                <Show when={session.canApply}>
                                                    <CMS id="salon-application/edit-submission::student-category-reminder">
                                                        <p>
                                                            * If you are a student trying to enter in the Teens Category, please go back to <a href="#/step-1">Step 1</a> and click the box to indicate that you are submitting artwork that was created at 13-19 years of age.
                                                        </p>
                                                    </CMS>
                                                    <div style="margin-top: 20px;">
                                                        <For each={categories()}>{(category: ListItem) =>
                                                            <Form.Check
                                                                label={category.Text}
                                                                name="categoryIds"
                                                                id={"categoryId-"+category.Value}
                                                                value={category.Value}
                                                                checked={(artwork?.categoryIds || []).some(item => item == category.Value)}
                                                                onChange={(e) => updateSelectedCategoryIds(e.currentTarget.value)}
                                                            />                                                
                                                        }</For>
                                                    </div>
                                                    <Show when={requiresCategoryId()}>
                                                        <div style="color: red;">
                                                            <CMS id="error::category/nominate-at-least-one">
                                                                You must nominate at least one category
                                                            </CMS>
                                                        </div>
                                                    </Show>
                                                </Show>
                                                <Show when={!session.canApply}>
                                                    <ul>
                                                        <For each={artwork.categoryIds}>{(id) =>
                                                            <li>
                                                                {categories().find(x => x.Value == id)?.Text || "Unknown category"}
                                                            </li>
                                                        }</For>
                                                    </ul>
                                                </Show>
                                            </Show>
                                            <Show when={session.isHighSchoolStudent}>
                                                <p>
                                                    <small>
                                                        <CMS id="salon-application/edit-submission::only-students-can-enter-student-category">
                                                            Because you've entered as a high school student your works can only be entered in the Teens Category.
                                                        </CMS>
                                                    </small>
                                                </p>
                                            </Show>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row" style="margin-top: 40px;">
                                        <div class="col-md-12">
                                            <CMS id="salon-application/edit-submission::purchase-award-nominated-price">
                                                <label>
                                                    Purchase award nominated price
                                                </label>
                                                <p>
                                                    If you would like to be eligible for a purchase award, enter the price of this artwork in US dollars. This is the price you would charge ARC if they are interested in purchasing the work. This price is not publicly listed anywhere on the website, no commissions are taken out of this price, and if the work is purchased by ARC, we will check with you for appropriate public retail pricing prior to our awards announcement. By entering a price you are NOT committing to sell the artwork to ARC. We do not mandate works be reserved for ARC to purchase during the entry process. If ARC is interested in purchasing the artwork, we will reach out to you to confirm price and availability. If the work is not available for sale or you would not like the work to be considered for a purchase award, just leave the field empty.
                                                </p>
                                                <p>
                                                    Artworks need not be for sale for all other awards.
                                                </p>
                                                <p>
                                                    <strong><em>Please just enter a number figure, without any other characters.</em></strong>
                                                </p>
                                            </CMS>
                                            <div class="row">
                                                <div class="col-md-3">
                                                    <input
                                                        type="number"
                                                        class="form-control"
                                                        name="purchaseAwardNominatedPrice"
                                                        value={artwork.purchaseAwardNominatedPrice}
                                                        onInput={(e) => setArtwork("purchaseAwardNominatedPrice", e.currentTarget.value)}
                                                        onChange={(e) => setArtworkWithEffect("purchaseAwardNominatedPrice", e.currentTarget.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <ErrorMessage error={errors.purchaseAwardNominatedPrice} />
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row" style="margin-top: 40px;">
                                        <div class="col-md-12">
                                            <label>
                                                <span class="fa fa-star" style="color: red;"></span>
                                                <CMS id="label::unit-of-measurement" text>Unit of measurement</CMS>
                                            </label>
                                            <div>
                                                <div class="btn-group" data-toggle="buttons">
                                                    <For each={unitOfMeasurements()}>{(unit: ListItem, index) =>
                                                        <Form.Check
                                                            inline
                                                            label={unit.Text}
                                                            value={unit.Value}
                                                            name="unitOfMeasurement"
                                                            checked={artwork.unitOfMeasurement === unit.Value}
                                                            onChange={(e) => setArtworkWithEffect("unitOfMeasurement", e.currentTarget.value)}
                                                            type="radio"
                                                            id={`unit-of-measurement-` + index()}
                                                        />
                                                    }</For>
                                                </div>
                                                <div>
                                                    <Show when={requiresUnitOfMeasurement()}>
                                                        <div style="color: red;">
                                                            <CMS id="error::unit-of-measurement/please-select-a-unit">Please select a unit of measurement</CMS>
                                                        </div>
                                                    </Show>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" style="margin-top: 20px;">
                                        <div class="col-md-4">
                                            <label>
                                                <Required /> <CMS id="label::height" text>Height</CMS>
                                            </label>
                                            <input
                                                type="number"
                                                min={0}
                                                class="form-control"
                                                value={artwork.height}
                                                name="height"
                                                onChange={(e) => setArtworkWithEffect("height", e.currentTarget.value)}
                                                required
                                                use:validate={[ required, onlyNumbers ]}
                                            ></input>
                                            <ErrorMessage error={errors.height} />
                                        </div>
                                        <div class="col-md-4">
                                            <label>
                                                <Required /> <CMS id="label::width" text>Width</CMS>
                                            </label>
                                            <input
                                                type="number"
                                                class="form-control"
                                                min={0}
                                                value={artwork.width}
                                                onChange={(e) => setArtworkWithEffect("width", e.currentTarget.value)}
                                                name="width"
                                                use:validate={[ required, onlyNumbers ]}
                                            ></input>
                                            <ErrorMessage error={errors.width} />
                                        </div>
                                        <Show when={isSculptureSelected()}>
                                            <div class="col-md-4">
                                                <label>
                                                    <Required /> <CMS id="label::depth" text>Depth</CMS>
                                                </label>
                                                <input
                                                    type="number"
                                                    class="form-control"
                                                    min={0}
                                                    value={artwork.depth}
                                                    onChange={(e) => setArtworkWithEffect("depth", e.currentTarget.value)}
                                                    name="depth"
                                                    use:validate={[onlyNumbers, () => isSculptureSelected() && !artwork.depth && "Please enter a value"]}
                                                ></input>
                                                <ErrorMessage error={errors.depth} />
                                            </div>
                                        </Show>
                                    </div>
                                    <p style="margin-top: 30px;">
                                        <b>
                                            <CMS id="salon-application::provide-frame-measurements">If the work is framed please provide the frame measurements</CMS>
                                        </b>
                                    </p>
                                    <div class="row">
                                        <div class="col-md-4">
                                            <label>
                                                <CMS id="form::height-framed" text>Height (framed)</CMS>
                                            </label>
                                            <input
                                                type="number"
                                                class="form-control"
                                                value={artwork.framed.height}
                                                onChange={(e) => setArtworkWithEffect("framed", "height", e.currentTarget.value)} />
                                        </div>
                                        <div class="col-md-4">
                                            <label>
                                                <CMS id="form::width-framed" text>Width (framed)</CMS>
                                            </label>
                                            <input
                                                type="number"
                                                class="form-control"
                                                value={artwork.framed.width}
                                                onChange={(e) => setArtworkWithEffect("framed", "width", e.currentTarget.value)} />
                                        </div>
                                    </div>
                                    <hr/>
                                    <Show when={session.isHighSchoolStudent}>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label>
                                                    <span class="fa fa-star" style="color: red;"></span>
                                                    <CMS id="label::student/age-at-completion" text>Your age at the time of completing this work</CMS>
                                                </label>
                                                <input
                                                    type="number"
                                                    class="form-control"
                                                    value={artwork.student?.age}
                                                    onChange={(e) => setArtworkWithEffect("student", "age", e.currentTarget.value)} />
                                            </div>
                                        </div>
                                    </Show>
                                    <div class="row" style="margin-top: 40px;">
                                        <div class="col-md-12">
                                            <label>
                                                <span class="fa fa-star" style="color: red;"></span> <CMS id="label::completed-in" text>Completed in</CMS>
                                            </label>
                                            <div class="row">
                                                <div class="col-md-3">
                                                    <select value={artwork.year}
                                                        class="form-control"                                                        
                                                        onInput={(e) => setArtworkWithEffect("year", e.currentTarget.value)}>
                                                        <For each={years()}>{(year: any) => <option value={year}>{year}</option>}</For>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" style="margin-top: 20px;">
                                        <div class="col-md-12">
                                            <label>
                                                <CMS id="salon-application/edit-submission::location/label" text>
                                                    Location (intended for use if the work is in a public collection or on public view)
                                                </CMS>
                                            </label>
                                            <input
                                                type="text"
                                                name="location"
                                                class="form-control"
                                                value={artwork.location}
                                                onInput={(e) => setArtwork("location", e.currentTarget.value)}
                                                onChange={(e) => setArtworkWithEffect("location", e.currentTarget.value)}
                                                use:validate={[ (el: any) => maxLength(el, 50)]}
                                            />
                                            <p>
                                                <CharacterCount value={artwork.location} max={50} />
                                            </p>
                                            <ErrorMessage error={errors.location}></ErrorMessage>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="row" style="margin-top: 40px;">
                                        <div class="col-md-12">
                                            <ModelEditor
                                                field="comment" editor="commentEditor"
                                                label={() =>
                                                <CMS id="salon-application/edit-submission::about-this-artwork">
                                                    <label>
                                                        <span class="fa fa-star" style="color: red;"></span> About this artwork
                                                    </label>
                                                    <p>
                                                        Please provide up to 150 words about this work of art. We may use this description on the ARC website if this artwork wins an award or honorable mention in the 17th ARC Salon Competition. We also may use this for other promotional materials as well. ARC reserves the right to edit your description for grammar and consistency.
                                                    </p>
                                                </CMS>
                                                }
                                            />
                                            <Show when={requiresComment()}>
                                                <div style="color: red;">
                                                    <CMS id="salon-application/edit-artwork::must-enter-at-least-150-words-error" text>Please provide up to 150 words about this work of art.</CMS>
                                                </div>
                                            </Show>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6" style="padding-left: 20px;">
                                <h4 class="salon-heading-md m-t-xxl m-b-lg">
                                    <CMS id="salon-application::heading/upload-artwork-images" text>Upload Artwork Images</CMS>
                                </h4>
                                <div class="row ">
                                    <div class="col-md-12">
                                        <PrepareImageText />
                                    </div>
                                    <div class="col-md-12">
                                        <div class="row" style="border: 1px solid #ccc; padding: 30px; margin: 0px; margin-top: 25px; border-radius: 10px;">
                                            <div class="col-md-12">
                                                <div>
                                                    <label style="font-size: 1.5em;">
                                                        <CMS id="label::image" text>Image</CMS>
                                                    </label>
                                                    <Show when={state.showFirstImageCropper}>
                                                        <ModelImageCropper imageUrl="image1_url" editor="image1" required />
                                                        <Show when={!!artwork.image1_url}>
                                                            <button class="btn btn-primary" style="width: 100%;" onClick={onKeepFirstImageClicked}>Keep Original Image</button>
                                                        </Show>
                                                    </Show>
                                                    <Show when={!state.showFirstImageCropper}>
                                                        <div style="text-align: center;">
                                                            <Img src={artwork.image1_url} width="400" mode="crop" />
                                                            <button class="btn btn-success" style="width: 100%;" onClick={uploadAnotherFirstImageClicked}>Choose Another Image</button>
                                                        </div>
                                                    </Show>
                                                </div>
                                                <div id="additional-images">
                                                    <div style="margin-top: 40px;">
                                                        <label style="font-size: 1.5em;">
                                                            <CMS id="label::second-image/optional" text>Second image (Optional)</CMS>
                                                        </label>
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <Show when={state.showSecondImageCropper}>
                                                                    <ModelImageCropper imageUrl="image2_url" editor="image2" required={() => isSculptureSelected() } />
                                                                    <Show when={!!artwork.image2_url}>
                                                                        <button class="btn btn-primary" style="width: 100%;" onClick={onKeepSecondImageClicked}>Keep Original Image</button>
                                                                    </Show>
                                                                </Show>
                                                                <Show when={!state.showSecondImageCropper}>
                                                                    <div style="text-align: center;">
                                                                        <Img src={artwork.image2_url} width="400" mode="crop" />
                                                                        <button class="btn btn-success" style="width: 100%;" onClick={uploadAnotherSecondImageClicked}>Choose Another Image</button>
                                                                    </div>
                                                                </Show>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style="margin-top: 40px;">
                                                        <label style="font-size: 1.5em;">
                                                            <CMS id="label::third-image/optional" text>Third image (Optional)</CMS>
                                                        </label>
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <Show when={state.showThirdImageCropper}>
                                                                    <ModelImageCropper imageUrl="image3_url" editor="image3" />
                                                                    <Show when={!!artwork.image3_url}>
                                                                        <button class="btn btn-primary" style="width: 100%;" onClick={onKeepThirdClicked}>Keep Original Image</button>
                                                                    </Show>
                                                                </Show>
                                                                <Show when={!state.showThirdImageCropper}>
                                                                    <div style="text-align: center;">
                                                                        <Img src={artwork.image3_url} width="400" mode="crop" />
                                                                        <button class="btn btn-success" style="width: 100%;" onClick={uploadAnotherThirdImageClicked}>Choose Another Image</button>
                                                                    </div>
                                                                </Show>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Show when={artwork.id == undefined}>
                            <div class="row">
                                <div class="col-md-6">
                                    <p>
                                        <CMS id="salon-application/edit-artwork::message/please-be-patient">
                                            <strong>Note:</strong> After you press the button to submit it may take quite a while for your files to upload, please be patient!
                                        </CMS>
                                    </p>
                                </div>
                            </div>
                        </Show>
                        <div class="row m-t-xxl">
                            <div class="col-md-12">
                                <div id="submit-row">
                                    <Button type="submit" variant="success" classList={{ ["disabled"] : !buttonActive.submit }} style="margin-right: 20px;">
                                        <Switch>
                                            <Match when={!!artwork.id}><CMS id="button::save-changes">Save Changes</CMS></Match>
                                            <Match when={!artwork.id}><CMS id="button::submit-this-artwork">Submit this Artwork</CMS></Match>
                                        </Switch>
                                    </Button>
                                    <Button type="button" variant="secondary" classList={{ ["disabled"] : !buttonActive.cancel }} href={"#/step-2"}>
                                        <CMS id="button::cancel">Cancel</CMS>
                                    </Button>
                                    {/* <Button type="button" variant="secondary" onClick={diagnoseClicked}>Diagnose</Button> */}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Show>
        </>
    );
}