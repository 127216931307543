import { Accessor, createEffect, createSignal, onCleanup } from "solid-js";
import Quill from "quill";
import { SolidQuill } from "solid-quill";
import '/node_modules/quill/dist/quill.core.css';
import '/node_modules/quill/dist/quill.bubble.css';
import '/node_modules/quill/dist/quill.snow.css';

export interface IQuillEditorWrapper {
  editor: Quill;
  setHtml: (html: string) => void;
  getHtml: () => string;
  isEmpty: Accessor<boolean>;
}

export type QuillEditorProps = {
  setInstance: (wrapper: IQuillEditorWrapper) => void;
  content?: string;
}

export default function QuillEditor(props: QuillEditorProps) {
    let quill: Quill;
    const [isEmpty, setIsEmpty] = createSignal<boolean>(true);
    const checkIsEmpty = () => {
      const html = quill.root.innerHTML;
      console.log("IQuillEditorWrapper.checkIsEmpty", { html });
      if (html == "") return true;
      if (html == "<p><br></p>") return true;
      return false;
    }
    const init = (quill: Quill) => {
      createEffect(() => {
        !!props.content ? quill.clipboard.dangerouslyPasteHTML(props.content) : quill.clipboard.dangerouslyPasteHTML("");
        setIsEmpty(checkIsEmpty());
      });
      console.log("QuillEditor.init", { quill, props, content: props.content });
      props.setInstance({
        editor: quill,
        setHtml: (html: string) => quill.clipboard.dangerouslyPasteHTML(html || ""),
        getHtml: () => quill.root.innerHTML,
        isEmpty: isEmpty
      } as IQuillEditorWrapper);
    };
    onCleanup(() => {
      if (quill) {
        console.log({ quill });
        quill.root.remove();
      }
    });
    return (
      <SolidQuill
          ref={quill}
          as="div"
          onReady={init}
          onTextChange={(e) => {
            setIsEmpty(checkIsEmpty());
          }}
          debug={false}
          modules={{toolbar: [
              ["bold", "italic", "underline", "strike"],
              [{ align: [] }],
              [{ list: "ordered" }, { list: "bullet" }],
              [{ indent: "-1" }, { indent: "+1" }],
              [{ size: ["small", false, "large", "huge"] }],
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              ["link", "image", "video"],
              [{ color: [] }, { background: [] }],
              ["clean"],
          ],
          clipboard: {
              matchVisual: false,
          }}}
          formats={[
              "bold",
              "italic",
              "underline",
              "strike",
              "align",
              "list",
              "indent",
              "size",
              "header",
              "link",
              "image",
              "video",
              "color",
              "background",
              "clean",
          ]}
          placeholder=""
          readOnly={false} // for now this is the only reactive props
          theme="snow"
          class="quill"
          style="background-color: #fff; height: 250px;"
          classList={{ active: true }}
          />
    );
  };
  