import { useNavigate, useRouteData } from "@solidjs/router"
import { onMount } from "solid-js";
import { fetchGet } from "../library/common";
import toast from "solid-toast";

export function PaypalConfirmedPage() {
    const navigate = useNavigate();
    const concludePayment = async (paymentId: string) => {
        const res = await fetchGet("/SalonApplication/Salon/PaypalConfirmJson", { paymentId });
        const json = await res.json();
        if (!json.Success) {
            return toast.error(json.ErrorMessage);
        }
        navigate("/payment/processed");
    }
    onMount(async () => {
        const routeData = await useRouteData() as any;
        await concludePayment(routeData.paymentId);
    })
    return (
        <>
            <div class="blank-page">
                <h2>
                    Finalising the Paypal payment now ...
                </h2>
            </div>
        </>
    )
}