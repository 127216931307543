import { Button } from "solid-bootstrap";
import { For, Show, createSignal, onMount } from "solid-js";
import { createStore } from "solid-js/store";
import { Progress } from "../components/progress";
import { useRouteData } from "@solidjs/router";
import { Address, ListItem, Step3Data } from "../types";
import { CatalogueOrders } from "../components/catalogue-orders";
import { commitChange, fetchGet, fetchPost } from "../library/common";
import { AddressForm } from "../components/address-form";
import toast from "solid-toast";
import { CMS } from "../components/cms/cms";
import { getText, loadStep3Cms } from "../app";

type AddressDto = {
    StreetAddress1: string;
    StreetAddress2: string;
    City: string;
    StateName: string;
    StateId: string;
    PostalCode: string;
    CountryId: string;
}
type State = {
    isReady: boolean;
    isContentReady: boolean;
}

export function Step3Page() {
    const [state, setState] = createStore<State>({ isReady: false, isContentReady: false });
    const [catalogues, setCatalogues] = createSignal<string>("");
    const [address, setAddress] = createStore<Address>({
        streetLine1: "",
        streetLine2: "",
        city: "",
        stateName: "",
        postalCode: "",
        countryId: ""
    });
    const [data, setData] = createStore<Step3Data>({} as Step3Data);
    const [total, setTotal] = createSignal<number>(0);
    const [owing, setOwing] = createSignal<number>(0);
    const [countries, setCountries] = createSignal<ListItem[]>([]);
    const updateTotal = () => {
        const _total: number = data
            .invoice
            .map((x: any) => x.value as number)
            .reduce((sum: number, a: number) => sum + a, 0);
            ;
        setTotal(_total);
        const _owing = _total - (data.salonEntrant.amountPaid || 0);
        setOwing(_owing);
    };
    const onCatalogueOrdersChanged = async (c: string) => {
        const res = await fetchGet("/SalonApplication/Salon/UpdateCatalogueOrdersJson", { catalogues: c });
        const json = await res.json();
        if (!json.Success) {
            const error = getText("salon-application/checkout::unable-to-update-number-of-catalogues-error", "Unable to update the number of catalogues order at this time.");
            return await commitError("[000] " + error);
        }
        toast.success("The number of catalogues orderered was updated.");
        setCatalogues(c);
        await updateInvoice();
    };
    const updateAddress = async (address: Address) => {
        const data = {
            StreetAddress1: address.streetLine1,
            StreetAddress2: address.streetLine2,
            City: address.city,
            StateName: address.stateName,
            PostalCode: address.postalCode,
            CountryId: address.countryId
        } as AddressDto;
        console.log("updateAddress", { address, data });
        const res = await fetchPost("/SalonApplication/Salon/UpdateAddressJson", data);
        const json = await res.json();
        if (!json.Success) {
            const error = getText("salon-application/checkout::unable-to-update-shipping-address-error", "Unable to update the shipping address at this time.");
            return await commitError("[001] " + error);
        }
        toast.success("Your shipping address details have been updated.");
        await updateInvoice();
    };
    const updateInvoice = async () => {
        const res = await fetch("/SalonApplication/Salon/GetInvoiceJson");
        const json = await res.json();
        if (!json.Success) {
            const error = getText("salon-application/checkout::retrieving-latest-invoice-error", "There was an error retrieving the latest invoice data.");
            return await commitError("[002] " + error);
        }
        setData("invoice", json.Data);
        updateTotal();
    };
    const commitError = async (error: string) => {
        await commitChange("17th-arc-salon-application/step-3/error", {
            Error: error
        });
        toast.error(error);
    };
    const paypalClicked = async (e: Event)  => {
        e.preventDefault();
        const res = await fetch("/SalonApplication/Salon/InitiatePaypalJson");
        const json = await res.json();
        if (!json.Success) {
            const error = getText("salon-application/checkout::paypal-transaction-error", "There was an error on the Paypal site:");
            return await commitError("[003] " + error +": " + json.ErrorMessage);
        }
        window.location.href = json.Data.ApproveUrl;
    };
    const hasOrderedCatalogue = () => !!catalogues() && catalogues() != "0";
    onMount(async () => {
        const routeData = await useRouteData() as any;
        await loadStep3Cms();
        setState({ isContentReady: true });
        setData(routeData.data);
        setCatalogues(data.salonEntrant.cataloguesOrdered?.toString());
        const _address = routeData.address as AddressDto;
        setAddress({
            streetLine1: _address.StreetAddress1,
            streetLine2: _address.StreetAddress2,
            stateName: _address.StateName,
            city: _address.City,
            postalCode: _address.PostalCode,
            countryId: _address.CountryId
        });
        setCountries(routeData.resources.countries);
        updateTotal();
        setState({ isReady: true });
    });
    return (
    <>
        <Show when={state.isContentReady}>
            <div>
                <Progress step={3}></Progress>
                <Show when={data.invoice?.length}>
                    <div class="salon-heading-xxl" style="text-align: center;">
                        <CMS id="salon-application/checkout::invoice" text>Invoice</CMS>
                    </div>
                    <div class="table-responsive">
                        <table class="table table-striped invoice">
                            <thead>
                                <tr>
                                    <th>
                                        <CMS id="salon-application/checkout::description" text>Description</CMS>
                                    </th>
                                    <th>
                                        <CMS id="salon-application/checkout::price" text>Price</CMS>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <For each={data.invoice}>{(item: any) =>
                                    <tr>
                                        <td>
                                            {item.description}
                                        </td>
                                        <td>
                                            ${item.value}
                                        </td>
                                    </tr>
                                }</For>
                                <tr>
                                    <td>
                                        <Show when={state.isReady}>
                                            <CatalogueOrders catalogues={catalogues} onChange={onCatalogueOrdersChanged} />
                                        </Show>
                                    </td>
                                    <td>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div>
                                            <Show when={state.isReady && hasOrderedCatalogue()}>
                                                <AddressForm model={address} onChange={updateAddress} countries={countries} />
                                            </Show>
                                        </div>
                                    </td>
                                    <td>
                                        
                                    </td>
                                </tr>
                                <Show when={!data.canApply}>
                                    <tr>
                                        <td colspan="2">
                                            <p class="m-t-xxl" style="text-align: center; font-size: 2em;">
                                                <CMS id="salon-application/checkout::nothing-in-cart" text>You have nothing in your cart at this time.</CMS>
                                            </p>
                                        </td>
                                    </tr>
                                </Show>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>
                                        <h4 style="font-weight: 600;text-align: left;">
                                            <CMS id="salon-application/checkout::total" text>Total</CMS>
                                        </h4>
                                    </th>
                                    <th>
                                        <h4 style="font-weight: 600; text-align: left;">
                                            ${total()}
                                        </h4>
                                    </th>
                                </tr>
                                <tr>
                                    <th>
                                        <h4 style="font-weight: 600;text-align: left;">
                                            <CMS id="salon-application/checkout::amount-already-paid" text>Amount already paid</CMS>
                                        </h4>
                                    </th>
                                    <th>
                                        <h4 style="font-weight: 600; text-align: left;">
                                            ${data.salonEntrant.amountPaid || 0}
                                        </h4>
                                    </th>
                                </tr>
                                <Show when={owing()}>
                                    <tr>
                                        <th>
                                            <h4 style="font-weight: 600; text-align: left;">
                                                <CMS id="salon-application/checkout::amount-owing" text>Amount owing</CMS>
                                            </h4>
                                            <p style="text-align: left;">
                                                <CMS id="salon-application/checkout::amount-due-to-complete-entry" text>This is the amount due to complete your entry</CMS>
                                            </p>
                                        </th>
                                        <th>
                                            <h4 style="color: red; font-weight: 600; text-align: left;">
                                                ${owing()}
                                            </h4>
                                        </th>
                                    </tr>
                                    <Show when={owing() > 0}>
                                        <tr>
                                            <th></th>
                                            <th style="vertical-align: middle;">
                                                <p>
                                                    <CMS id="salon-application/checkout::payment-method" text>Payment Method</CMS>
                                                </p>
                                                <p>
                                                    <Button variant="primary" style="font-size: 1.25em;" href={"#/credit-card"}>
                                                        <CMS text id="salon-application/checkout::credit-card">Credit Card</CMS>
                                                    </Button>
                                                </p>
                                                <p>
                                                    <Button class="btn btn-primary" style="font-size: 1.25em;" onClick={paypalClicked}>
                                                        <CMS text id="salon-application/checkout::paypal">Paypal</CMS>
                                                    </Button>
                                                </p>
                                            </th>
                                        </tr>
                                    </Show>
                                </Show>
                                <Show when={!owing()}>
                                    <tr>
                                        <th>

                                        </th>
                                        <th>
                                            <h4 style="font-weight: 600; text-align: left;">
                                                <span class="glyphicon glyphicon-ok" style="color: green;"></span>
                                                <CMS id="salon-application/checkout::fully-paid" text>Fully Paid</CMS>
                                            </h4>
                                        </th>
                                    </tr>
                                </Show>
                            </tfoot>
                        </table>
                    </div>
                </Show>
            </div>
        </Show>
    </>);
}