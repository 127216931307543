import { Component, createSignal, Match, Switch } from "solid-js";
import { TextInput } from "../components/form-controls";
import { fetchGet } from "../library/common";

export type ForgotPasswordProps = {
    onCancel: () => void;
    onCompleted: () => void;
};

type Screen = "form" | "completed" | "error";

export const ForgotPassword : Component<ForgotPasswordProps> = (props) => {
    const [screen, setScreen] = createSignal<Screen>("form");
    const [username, setUsername] = createSignal<string>("");
    const cancelClicked = (e: Event) => {
        e.preventDefault();
        props.onCancel();
    }
    const onSubmit = async (e: Event) => {
        e.preventDefault();
        const response = await fetchGet("/Home/ForgotPasswordJson", { UsernameOrEmail: username() });
        const json = await response.json();
        if (!json.Success) {
            setScreen("error");
            return;
        };
        setScreen("completed");
    };
    const retryClicked = (e: Event) => {
        e.preventDefault();
        setScreen("form");
    };
    const closeClicked = (e: Event) => {
        e.preventDefault();
        props.onCompleted();
    };
    return (
    <div>
        <Switch>
            <Match when={screen()== "form"}>
                <form onSubmit={onSubmit}>
                    <div class="row">
                        <div class="col-md-6">
                            <h2>
                                Forgot Password?
                            </h2>
                            <p>
                                Enter your username or email below to be sent an email that will enable you to reset your password.
                            </p>
                            <div>
                                <label>
                                    Username or Email
                                </label>
                                <TextInput field={[username, setUsername]}></TextInput>
                            </div>
                            <button class="btn btn-default" type="button" onClick={cancelClicked}>Cancel</button>
                            <button class="btn btn-primary" type="submit">Reset Password</button>
                        </div>
                    </div>
                </form>
            </Match>
            <Match when={screen() == "completed"}>
                <div>
                    <h2>
                        Next steps ...
                    </h2>
                    <p>
                        We have sent a password reset email to the address associated with your account.
                    </p>
                    <p>
                        Please follow the instructions in the email to complete the password reset process.
                    </p>
                    <button type="button" class="btn btn-default" onClick={closeClicked}>Close</button>
                </div>
            </Match>
            <Match when={screen() == "error"}>
                <div>
                    <h2>
                        Error
                    </h2>
                    <p>
                        Unfortunately we were not able to complete that action at this time.
                    </p>
                    <p>
                        Please try again or contact Iian Neill, the <a href="mailto:webmaster@artrenewal.org">ARC Webmaster</a>.
                    </p>
                    <p>
                        <button class="btn btn-primary" type="button" onClick={retryClicked}>OK</button>
                    </p>
                </div>
            </Match>
        </Switch>
    </div>
    );
}