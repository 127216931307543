import { Component, createSignal, For, onMount, Show } from "solid-js";
import { Blog } from "../Types";

export const LatestBlogs: Component = () => {
    const [blogs, setBlogs] = createSignal<Blog[]>([]);
    const [firstBlog, setFirstBlog] = createSignal<Blog>();
    const getLatestBlogs = async () => {
        const response = await fetch("/Footer/GetLatestBlogsJson");
        const json = await response.json();
        return json;
    };
    const toDate = (jsonDate: string) => new Date(parseInt(jsonDate.substr(6)));
    const loadBlogs = async () => {
        const response = await getLatestBlogs();
        console.log("LatestBlogs", { response });
        if (!response.Success) return;
        const blogs = response.Data.map((x: any) => ({ ...x, dateAddedUTC: toDate(x.dateAddedUTC) }));
        setBlogs(blogs);
        setFirstBlog(blogs[0]);
    };
    onMount(async () => {
        await loadBlogs();
    });
    return (
        <Show when={firstBlog()}>
            <div>
                Show all posts for <a href={ "/Blog/Month/" + firstBlog()!.dateAddedUTC?.getFullYear() + (firstBlog()!.dateAddedUTC.getMonth() + 1) }>
                    {firstBlog()!.dateAddedUTC.toLocaleDateString("en-US", { year: 'numeric', month: 'long' })}
                </a>
                <For each={blogs()}>{(blog) => 
                <>
                    <div>
                        <h4>
                            <a href={"/Blog/Index/" + blog.id}>
                                {blog.title}
                            </a>
                        </h4>
                        <p>
                            on {blog.dateAddedFormatted}
                        </p>
                    </div>
                </>
                }</For>
            </div>
        </Show>
    );
};