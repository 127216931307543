/* @refresh reload */
import '/node_modules/cropperjs/dist/cropper.css'
import './assets/jetmenu.scss'
import './assets/arc/arc.less'
import "./assets/back-to-top.scss"
import './assets/styles.less'
import './assets/bootstrap-wizard.less'
import './assets/bootstrap-overrides.less'
//import './assets/bootstrap.min.css'
import './assets/Edit.css'
import { MountableElement, render } from 'solid-js/web';
import './modules/type-extensions';
import { App } from './app';
render(() => <App />, document.getElementById('root') as MountableElement);