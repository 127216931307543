import { Show, onMount } from "solid-js";
import { CMS } from "../components/cms/cms";
import { Progress } from "../components/progress";
import { loadRulesCms } from "../app";
import { createStore } from "solid-js/store";
import { A, useRouteData } from "@solidjs/router";
import { UserSession } from "../types";

type State = {
    isContentReady: boolean;
}
export function RulesPage() {
    const [state, setState] = createStore<State>({ isContentReady: false });
    const [session, setSession] = createStore<UserSession>({
        canApply: false,
        isAdmin: false,
        cultureCode: "",
        itemCount: 0,
        isLoggedIn: false,
        isHighSchoolStudent: false,
        isRegistered: false,
        member: {
            id: 0, firstName: "", lastName: "", permissions: [],
            memberType: "", username: ""
        }    
    } as UserSession);
    onMount(async() => {
        const routeData = await useRouteData() as any;
        await loadRulesCms();
        setSession(routeData.session);
        setState({ isContentReady: true });
    });
    return (
        <>
            <Show when={state.isContentReady}>
                <div class="article">
                    <div class="container">
                        <Progress step={0} />
                        <CMS id="salon-application/rules::page-body"></CMS>
                        <Show when={session.canApply || session.isAdmin}>
                            <div style="padding: 60px; margin-bottom: -40px;">
                                <h2 style="text-align: center; font-family: 'Impact';">
                                    <A class="btn btn-primary" href="/step-1" style="font-size: 2rem;">
                                        <CMS text id="button::update/start-application">Update/Start Application</CMS> <span class="fa fa-arrow-circle-right pulse" style="margin-left: 20px;"></span>
                                    </A>
                                </h2>
                            </div>
                        </Show>
                    </div>
                </div>
            </Show>
        </>
    )
}