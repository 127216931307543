import { Component, Show, onMount } from "solid-js";
import _ from "underscore";
import { A, useNavigate, useRouteData } from "@solidjs/router";
import { commitChange, fetchPost, scrollToTop } from "../library/common";
import ImageCropper, { IImageCropperWrapper,  ImageFileData } from "../components/universal/image-cropper";
import { ArtworkImageData, KeyOfType } from "../types";
import { Progress } from "../components/progress";
import { createStore } from "solid-js/store";
import { PrepareImageText } from "../components/prepare-image-text";
import toast from "solid-toast";
import { CMS } from "../components/cms/cms";
import { loadCms } from "../app";

type State = {
    isContentReady: boolean;
}
export function EditImage() {  
    const navigate = useNavigate();
    const [state, setState] = createStore<State>({ isContentReady: false });
    const [data, setData] = createStore<ArtworkImageData>({} as any);
    const toSalonEntrantImageData = (salonSubmissionBaseId: string, imageFile: ImageFileData) => {
        let model = {
            isPrimary: data.isPrimary,
            salonSubmissionBaseId: salonSubmissionBaseId || "",
            newImageId: data.newImage.id || "",
            image: imageFile.image,
            filename: imageFile.filename
        };
        return model;
    };
    const ModelImageCropper : Component<{ imageUrl: keyof KeyOfType<ArtworkImageData, string>; required?: boolean |(() => boolean); editor: keyof KeyOfType<ArtworkImageData, IImageCropperWrapper>; label?: string; }> = (props) =>
    <>
        <ImageCropper src={data[props.imageUrl]} required={props.required} maxHeight={4000} maxWidth={4000} setInstance={(wrapper) => setData(props.editor, wrapper)} />
    </>;
    const saveImage = async (imageFile: ImageFileData) => {
        const model = toSalonEntrantImageData(data.artwork.id, imageFile);
        const response = await fetchPost("/SalonApplication/SalonSubmission/SavePhotoJson", model);
        const toastId = toast.loading("Uploading the image ...");
        const json = await response.json();
        if (!json.Success) {
            await commitError("There was an error uploading the image.");
            return toast.error("There was an error uploading the image.", { id: toastId });
        }
        toast.success(`Image of "${data.artwork.title}" has been saved!`, { id: toastId });
        navigate("/step-2");
    }
    const commitError = async (error: string) => {
        await commitChange("17th-arc-salon-application/edit-image/error", {
            SalonSubmissionBaseImageId: data.id,
            SalonSubmissionBaseId: data.artwork?.id,
            Error: error
        });
        toast.error(error);
    }
    const onSaveClicked = async (e: Event) => {
        e.preventDefault();
        if (data.artwork.id === undefined) {
            return await commitError("Artwork data was not retrieved. Please refresh the page.");
        }
        const imageFile = await data.imageEditor?.getImageFile();
        if (imageFile == null) {
            return await commitError("There is no image selected to upload.");
        }
        await commitChange("17th-arc-salon-application/edit-image", {
            SalonSubmissionBaseImageId: data.id,
            SalonSubmissionBaseId: data.artwork?.id,
            OriginalFilename: imageFile.filename,
            FileSize: imageFile?.image?.size,
            Errors: imageFile?.errors,
            IsValid: imageFile?.isValid,
            IsDirty: imageFile?.isDirty
        });
        await saveImage(imageFile);
    }
    onMount(async () => {
        const routeData = await useRouteData() as ArtworkImageData;
        console.log("onMount", { routeData });
        setData(routeData);
        if (!!routeData.newImage?.url) {
            setData("imageUrl", routeData.newImage.url);
        }
        await loadCms([
            "button::cancel", "button::save"
        ]);
        setState({ isContentReady: true });
        scrollToTop();
    });
    return (
        <>
            <div>
                <Progress step={2}></Progress>
                <div class="row ">
                    <div class="col-md-12">
                        <PrepareImageText />
                    </div>
                </div>
                <Show when={state.isContentReady}>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="row" style="border: 1px solid #ccc; padding: 30px; margin: 0px; margin-top: 25px; border-radius: 10px;">
                                <div class="col-md-12">
                                    <div>
                                        <label style="font-size: 1.5em;">
                                            <CMS id="label::image">Image</CMS>
                                        </label>
                                        <ModelImageCropper imageUrl="imageUrl" editor="imageEditor" required />
                                    </div>                                
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <A href={"/step-2"} class="btn btn-default">
                                <CMS text id="button::cancel">Cancel</CMS>
                            </A>
                            <button class="btn btn-primary" onClick={onSaveClicked}>
                                <CMS text id="button::save">Save</CMS>
                            </button>
                        </div>
                    </div>
                </Show>
            </div>
        </>
    );
}