import { createEffect, createSignal, For, Show  } from "solid-js";
import Cropper from 'cropperjs';
import { createDropzone } from "@solid-primitives/upload";
import { createStore } from "solid-js/store";
import '/node_modules/cropperjs/dist/cropper.css';

type ImageCropperProps = {
    maxWidth?: number;
    maxHeight?: number;
    required?: boolean |(() => boolean);
    setInstance: (wrapper: IImageCropperWrapper) => void;
    src: string;
    filename?: () => string;
};
export type ImageFileData = {
    id: number|undefined;
    isDirty: boolean;
    isValid: boolean;
    errors: string[];
    image: Blob;
    filename: string;
};
export interface IImageCropperWrapper {
    getImageFile(): Promise<ImageFileData|null>
};
const _getBlob = async (canvas: HTMLCanvasElement) => new Promise((resolve: (blob: Blob) => void) => canvas.toBlob((blob) => resolve(blob as Blob)));
export const getImage = async (cropper: Cropper) => {
    const canvas = cropper.getCroppedCanvas();
    if (!canvas) return null;
    const blob = await _getBlob(canvas);
    return blob;
};
const isImageFile = (file: File) => /^image\/\w+$/.test(file.type);
export default function ImageCropper(props: ImageCropperProps) {
    let isDirty = false;
    const [src, setSrc] = createSignal<string>();
    const [dropzoneColour, setDropzoneColour] = createSignal<string>("#ccc");
    const [filename, setFilename] = createSignal<string>(props.filename && props.filename() || "");
    const [width, setWidth] = createSignal<number>(0);
    const [height, setHeight] = createSignal<number>(0);
    const [errors, setErrors] = createStore<any>({
        dimensions: "",
        filetype: ""
    });
    const { setRef: dropzoneRef, files: droppedFiles } = createDropzone({
        onDrop: async files => { loadImageFromFileInput(files[0].file); setDropzoneColour("#ccc"); },
        onDragStart: async () => { setDropzoneColour("orange");  },
        onDragLeave: async () => { setDropzoneColour("#ccc"); },
        onDragEnter: async () => { setDropzoneColour("red"); },
        onDragOver: async () => { setDropzoneColour("green"); },
    });
    const [error, setError] = createSignal<string>("");
    let cropper: Cropper;
    const move = () => { cropper.setDragMode("move");  }
    const crop = () => { cropper.setDragMode("crop");  }
    const cut = async () => {
        const blob = await getBlob();
        const url = URL.createObjectURL(blob as Blob);
        cropper.replace(url).reset().clear();
    };
    const clear = () => { cropper.clear();  }
    const reset = () => { cropper.reset();  }
    const getCanvas = () => cropper.getCroppedCanvas();
    const getBlob = async () => new Promise((resolve: (blob: Blob) => void) => getCanvas().toBlob((blob) => resolve(blob as Blob)));
    const getData = () => cropper.getData();
    const scaleX = () => getData().scaleX;
    const scaleY = () => getData().scaleY;
    const flipHorizontal =  () => { cropper.scaleX(-1 * scaleX());  };
    const flipVertical =  () => { cropper.scaleY(-1 * scaleY());  };
    const zoomIn =  () => { cropper.zoom(0.1);  };
    const zoomOut =  () => { cropper.zoom(-0.1);  };
    const rotateLeft45 =  () => { cropper.rotate(-45);  }
    const rotateRight45 =  () => { cropper.rotate(45);  }
    const hasErrors = () => {
        for (let key in Object.keys(errors)){
            if (!!errors[key]) return true;
        }
        return false;
    }
    const clearErrors = () => setErrors({ dimensions: "", filetype: "", required: "" });
    const getTruthy = (value: boolean |(() => boolean)|undefined) => {
        if (typeof value === "undefined") {
            return false;
        }
        if (value instanceof Boolean) {
            return value as boolean;
        }
        if (value instanceof Function) {
            return value();
        }
        return false;
    }
    const getFileExtension = (filename: string) => {
        if (!filename) return null;
        const parts = filename.split(".");
        return parts[parts.length-1];
    }
    const validate = () => {
        clearErrors();
        var isValid = true;
        const required = getTruthy(props.required);
        if (required) {
            if (isDirty && !filename()) {
                isValid = false;
                console.log("validate", { filename: filename(), isDirty });
                setErrors({ required: "You must select an image." });
            }
        }
        const extension = getFileExtension(filename());
        if (!extension) {
            isValid = false;
            setErrors("filetype", "No file type was found.");
        }
        else {
            const validFileTypes = ["JPG","JPEG","PNG"];
            if (!validFileTypes.some(type => type.toLowerCase() == extension.toLowerCase())) {
                isValid = false;
                setErrors("filetype", "File type not accepted. Please choose a file of one of these types: " + validFileTypes.join(", "));
            }
        }
        if (props.maxHeight || props.maxWidth) {
            if (props.maxHeight && height() > props.maxHeight){
                isValid = false;
                setErrors({ dimensions: `The image is too large. The height can be no more than ${props.maxHeight} pixels` });
            }
            if (props.maxWidth && width() > props.maxWidth){
                isValid = false;
                setErrors({ dimensions: `The image is too large. The width can be no more than ${props.maxWidth} pixels` });
            }
        }
        return isValid;
    };
    const setDimensions = () => {
        const data = cropper.getImageData();
        // console.log("setDimensions", { data, cropper });
        setWidth(data.naturalWidth);
        setHeight(data.naturalHeight);
    };
    const loadImageFromFileInput = (file: File) => {
        let blobURL = URL.createObjectURL(file);
        setFilename(file.name);
        console.log("loadImageFromFileInput", { filename: file.name, file });
        cropper.reset().replace(blobURL)// .clear();
        // console.log("loadImageFromFileInput", { file, blobURL });
        setDimensions();
        validate();
    };
    const loadImageFromUrl = (imageUrl: string) => {
        const fn = getFilename(imageUrl);
        console.log("loadImageFromUrl", { filename: fn, imageUrl });
        setFilename(fn);
        cropper.reset().replace(imageUrl)//.clear();  
        // console.log("loadImageFromUrl", { imageUrl });
        setDimensions();
        validate();      
    };
    let fileinput: HTMLInputElement;
    const setupFileInputElement = (element: HTMLInputElement) => {
        fileinput = element;
        fileinput.addEventListener("change", () => {
            let files = element.files;
            if (!files || !files.length) return;
            let file = files[0];
            if (!isImageFile(file)) {
                setError("Please choose an image file (either JPG or PNG).");
                return;
            }
            isDirty = true;
            // console.log("setupFileInputElement", { file });
            loadImageFromFileInput(file);
        });
    };
    const onUploadClicked = () => fileinput.click();
    const getFilename = (str: string) => !str ? "" : str.split('\\').pop()?.split('/').pop() || "";
    const options: Cropper.Options<HTMLImageElement> = {
        preview: ".img-preview",
        dragMode: "none",
        autoCrop: false,
        movable: false,
        zoomable: false,
        scalable: false,
        cropBoxMovable: false,
        cropBoxResizable: false,
        ready() {
            console.log("options:ready()");
            setDimensions();
            validate();
        }     
    };
    let imageLoaded = false;
    createEffect(() => {
        if (!imageLoaded) {
            if (src()) {
                loadImageFromUrl("/secureimages" + src());
                imageLoaded = true;
            }            
        }
    });
    createEffect(() => {
        !!props.src ? setSrc(props.src) : setSrc(undefined);
    });
    const blackPixel = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAYAAABytg0kAAAAAXNSR0IArs4c6QAAAAlwSFlzAAAWJQAAFiUBSVIk8AAAABNJREFUCB1jZGBg+A/EDEwgAgQADigBA//q6GsAAAAASUVORK5CYII%3D";
    const setupImageCropperElement = (img: HTMLImageElement) => {
        cropper = new Cropper(img, options);
        const wrapper = {
            async getImageFile() {
                const canvas = cropper.getCroppedCanvas();
                if (!canvas) return null;
                const blob = await _getBlob(canvas);
                setDimensions();
                const errs = [errors.dimensions, errors.filetype].filter(x => !!x);
                return {
                    isDirty,
                    isValid: validate(),
                    errors: errs,
                    image: blob,
                    filename: filename()
                } as ImageFileData
            }
        } as IImageCropperWrapper;
        props.setInstance(wrapper);
    };
    return (
        <div ref={dropzoneRef} style={{ "border": "4px solid " + dropzoneColour() }}>
            <div style="width: 100%;">
                <img style="display: block; max-width: 100%;"
                    src={src()}
                    ref={setupImageCropperElement}
                />
            </div>
            <div style="background-color: #333; color: #efefef; padding: 10px;">
                <div class="docs-buttons">
                    <div style="text-align: center;">
                        <button type="button" onClick={onUploadClicked} class="btn btn-success btn-upload">
                            <input style="display: none;" type="file" ref={setupFileInputElement} />
                            <span class="docs-tooltip" title="Upload image">
                                <span class="fa fa-upload" style="font-size: 1.5em;"></span> Choose File to Upload
                            </span>
                        </button>
                    </div>
                    <div>
                        <Show when={filename()}>
                            <div>
                                File: {filename()}
                            </div>
                        </Show>
                        <Show when={height() && width()}>
                            <div>
                                {height()} <small>&#10005;</small> {width()} pixels
                            </div>
                        </Show>
                        <For each={Object.keys(errors)}>{(key) =>
                           <div style="color: red; margin: 5px;">
                               {errors[key]}
                           </div>
                        }</For>
                    </div>
                </div>
            </div>
        </div>
    );
}