import { Button, InputGroup, Pagination } from 'solid-bootstrap';
import {Accessor, Component, createEffect, createSignal, For, onMount, Setter, Show} from 'solid-js';
import { SetStoreFunction, Store } from 'solid-js/store';
import { getOrSetAsync } from '../API';

type RadioButtonProps = {
    name: string;
    value: string;
    text?: string;
    field: [Accessor<string>, Setter<string>];
};
export const RadioButton: Component<RadioButtonProps> = (props) =>
<label class="radio-inline">
    <input type="radio" name={props.name} checked={props.field[0]() == props.value} onChange={() => props.field[1](props.value)} /> <i></i> <span style="position: relative; top: 6px;">{(props.text || props.value)}</span>
</label>;

export const makeAccessor = (list: any) => createSignal<any[]>(list)[0];

type PageListProps = {
    maxPage: Accessor<number>;
    blockSize: Accessor<number>;
    page: Accessor<number>;
    onClick: (page: number) => void;
};
export const PageList: Component<PageListProps> = (props) => {
    const [pageBlock, setPageBlock] = createSignal<number[]>([]);
    const getPageBlockStart = (pageIndex: number, pageBlockSize: number) => {
        var pageBlock = Math.floor((pageIndex / pageBlockSize) + 1);
        var pageBlockStart = ((pageBlock - 1) * pageBlockSize) + 1;
        return pageBlockStart;
    };
    const getPageRange = (page: number, rangeSize: number, maxPage: number) => {
        var start = getPageBlockStart(page, rangeSize);
        var end = start + rangeSize - 1;
        if (end > maxPage) {
            end = maxPage;
        }
        var pages = [];
        for (var i = start; i <= end; i++) {
            pages.push(i);
        }
        return pages;
    };
    const skipToPreviousBlock = async () =>{
        const pageBlockStart = getPageBlockStart(props.page(), props.blockSize());
        if (pageBlockStart - props.blockSize() >= 1) {
            const previousPageBlockStart = pageBlockStart - props.blockSize();
            props.onClick(previousPageBlockStart);
        }
    };
    const skipToNextBlock = async () =>{
        const pageBlockStart = getPageBlockStart(props.page(), props.blockSize());
        if (pageBlockStart + props.blockSize() <= props.maxPage()) {
            const nextPageBlockStart = pageBlockStart + props.blockSize();
            props.onClick(nextPageBlockStart);
        }
    };
    const skipToFirst = () => props.onClick(1);
    const skipToLast = () => props.onClick(props.maxPage());
    createEffect(() => {
        const _pageBlock = getPageRange(props.page(), props.blockSize(), props.maxPage());
        setPageBlock(_pageBlock);
    });
    return (
        <>
            <Pagination>
                <Pagination.First onClick={skipToFirst} />
                <Pagination.Prev onClick={skipToPreviousBlock} />
                <For each={pageBlock()}>{(p) =>
                    <Pagination.Item active={p == props.page()} onClick={() => props.onClick(p)}>{p}</Pagination.Item>
                }</For>
                <Pagination.Next onClick={skipToNextBlock} />
                <Pagination.Last onClick={skipToLast} />
            </Pagination>
        </>
    );
};

type AlphabeticalListProps = {
    letter: Accessor<string>;
    existingLetters?: Accessor<string[]>;
    onClick: (letter: string) => void;
};
export const AlphabeticalList: Component<AlphabeticalListProps> = (props) => {
    const createAlphabet = () => {
        const alpha = Array.from(Array(26)).map((e, i) => i + 65);
        const alphabet = alpha.map((x) => String.fromCharCode(x));
        return alphabet;
    };
    const letters = createAlphabet();
    const [existing, setExisting ] = createSignal<string[]>(letters);
    createEffect(() => {
        if (props.existingLetters()) setExisting(props.existingLetters());
    });
    console.log({ existing: existing(), letters: letters });
    return (
        <For each={letters}>{(l: string) =>
            <>
                <Show when={existing().indexOf(l) >= 0}>
                    <span class="alphabet-index" onClick={() => props.onClick(l)} classList={{ ["active-letter"]: l == props.letter() }}>{l}</span>
                </Show>
                <Show when={existing().indexOf(l) < 0}>
                    <span style="padding: 5px; color: #ccc;">{l}</span>
                </Show>
            </> 
        }</For>
    );
}

type SelectListFieldProps = {
    class?: string;
    list: Accessor<any[]>;
    field: [string, Store<{[key: string]: string}>, SetStoreFunction<string>];
};
export const SelectListField: Component<SelectListFieldProps> = (props) => 
<select class="form-control" value={props.field[1][props.field[0]]} onChange={(e) => props.field[2](props.field[0], e.currentTarget.value)}>
    <For each={props.list()}>{(item: any) =><option value={item.Value}>{item.Text}</option>}</For>
</select>;


type SelectListProps = {
    class?: string;
    size?: number;
    list: Accessor<any[]>;
    field: [Accessor<string>, Setter<string>];
};
export const SelectList: Component<SelectListProps> = (props) => {    
    const value = props.field[0];
    const setValue = props.field[1];
    const size = props.size || 1;
    createEffect(() => {
        if (props.list().length > 0) {
            if (value() === "" || value() === null || value() === undefined) {
                setValue(props.list()[0]?.Value || "");
            }
        }
    });
    return (
    <select class="form-control" value={value()} size={size} onChange={(e) => setValue(e.currentTarget.value)}>
        <For each={props.list()}>{(item: any) =><option value={item.Value?.toString()}>{item.Text}</option>}</For>
    </select>
    );
};

type TextInputProps = {
    field: [Accessor<string>, Setter<string>];
};
export const TextInput : Component<TextInputProps> = (props) => <input type="text" class="form-control" value={props.field[0]()} onInput={(e) => props.field[1](e.currentTarget.value)} />;

type NumericalSelectListProps = {
    class?: string;
    list: Accessor<number[]>;
    field: [Accessor<number>, Setter<number>];
};
export const NumericalSelectList : Component<NumericalSelectListProps> = (props) => 
<select class="form-control" value={props.field[0]()} onChange={(e) => props.field[1](parseInt(e.currentTarget?.value))}>
    <For each={props.list()}>{(item: number) =><option value={item}>{item}</option>}</For>
</select>;

type ListItem = {
    Id: string;
    Name: string;
    IsDeleted: boolean;
};

type PageSelectListProps = {
    maxPage: Accessor<number>;
    field: [Accessor<number>, Setter<number>];
};
export const PageSelectList: Component<PageSelectListProps> = (props) => {
    const [pages, setPages] = createSignal<number[]>([]);
    createEffect(() => {
        const list = [];
        for (let p = 1; p < props.maxPage(); p++) {
            list.push(p);
        }
        setPages(list);
    });
    return (
        <NumericalSelectList field={props.field} list={pages}></NumericalSelectList>
    );
};


type SelectArtistProps = {
    onSelected: (id: string, name: string) => void;
    defaultValue?: Accessor<string>;
};
export const SelectArtist : Component<SelectArtistProps> = (props: SelectArtistProps) => {
    const [artistId, setArtistId] = createSignal<string>("");
    const [artists, setArtists] = createSignal<any[]>([]);
    const getData = async () => {
        const response = await fetch("/Admin/Artist/GetListItemsJson");
        const json = await response.json();
        return json;
    };
    const updateArtists = async () => {
        const json = await getOrSetAsync("artists/select-list", () => getData());
        setArtists(json.Data.map((x: ListItem) => {
            let text = x.Name;
            if (!!x.IsDeleted) {
                text += " [DELETED]";
            }
            return {
                Text: text,
                Value: x.Id
            };
        }));
        if (props.defaultValue) {
            setArtistId(props.defaultValue());
        }
    };
    const artistSelected = (e: Event) => {
        e.preventDefault();
        const id = parseInt(artistId());
        const text = artists().find(x => x.Value == id)?.Text;
        props.onSelected(artistId(), text || "<unknown>");
    };
    onMount(async () => {
        await updateArtists();
    });
    return (
    <div>
        <InputGroup class="mb-3">
            <SelectList field={[artistId, setArtistId]} list={artists}></SelectList>
            <Button variant="primary" onClick={artistSelected}>Select</Button>
        </InputGroup>
    </div>
    );
}

type SelectInstitutionProps = {
    onSelected: (id: string, name: string) => void;
    defaultValue?: Accessor<string>;
};
export const SelectInstitution : Component<SelectInstitutionProps> = (props: SelectInstitutionProps) => {
    const [institutionId, setInstitutionId] = createSignal<string>("");
    const [institutions, setInstitutions] = createSignal<any[]>([]);
    const getData = async () => {
        const response = await fetch("/Admin/Institution/GetListItemsJson");
        const json = await response.json();
        return json;
    };
    const updateInstitutions = async () => {
        const json = await getOrSetAsync("institutions/select-list", () => getData());
        setInstitutions(json.Data.map((x: ListItem) => {
            let text = x.Name;
            if (!!x.IsDeleted) {
                text += " [DELETED]";
            }
            return {
                Text: text,
                Value: x.Id
            };
        }));
        if (props.defaultValue) {
            setInstitutionId(props.defaultValue());
        }
    };
    const institutionSelected = (e: Event) => {
        e.preventDefault();
        const id = parseInt(institutionId());
        const text = institutions().find(x => x.Value == id)?.Text;
        props.onSelected(institutionId(), text || "<unknown>");
    };
    onMount(async () => {
        await updateInstitutions();
    });
    return (
    <div>
        <InputGroup class="mb-3">
            <SelectList field={[institutionId, setInstitutionId]} list={institutions}></SelectList>
            <Button variant="primary" onClick={institutionSelected}>Select</Button>
        </InputGroup>
    </div>
    );
}