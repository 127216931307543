import {Component, Show, createSignal } from 'solid-js';
import { CMSBlock, CMSProviderType, useCMS } from './provider';
import { formatText } from '../../library/common';

export interface cmsProps {
    id: string;
    text?: boolean;
    data?: Record<string,string|number>;
    children?: any;
};
export const plainText = (html: string) => {
    const div = document.createElement("DIV");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
};


export const CMS : Component<cmsProps> = (props) => {
    const provider = useCMS() as CMSProviderType;
    let cmsValue = provider?.cms[props.id];
    if (!cmsValue) {
        if (provider.setCms) {
            cmsValue = { found: false, item: props.children } as CMSBlock;
            provider.setCms(props.id, cmsValue);
        }
    }
    const bindData = (block: CMSBlock) => {
        if (!block) return null;
        if (!props.data) return block.item;
        console.log("bindData:hasData", { block, props });
        let text = formatText(block.item, props.data);
        return text;
    };
    const [value, setValue] = createSignal<string>(bindData(cmsValue) || "");
    return (
        <>
            <Show when={cmsValue?.found}>
                <Show when={props.text}>
                    <span innerText={plainText(value())} />
                </Show>
                <Show when={!props.text}>
                    <span innerHTML={value()} />
                </Show>
            </Show>
            <Show when={!cmsValue?.found}>
                <Show when={props.data}>
                    {value()}
                </Show>
                <Show when={!props.data}>
                    {props.children}    
                </Show>
            </Show>
        </>
    )
}