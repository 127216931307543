import { Show, onMount } from "solid-js";
import { loadIntroductionCms } from "../app";
import { CMS } from "../components/cms/cms";
import { Progress } from "../components/progress";
import { Img } from "../components/universal/image";
import { createStore } from "solid-js/store";
import { A, useRouteData } from "@solidjs/router";
import { UserSession } from "../types";

type State = {
    isContentReady: boolean;
}
export function Introduction() {
    const [state, setState] = createStore<State>({ isContentReady: false } as State);
    const [session, setSession] = createStore<UserSession>({ } as UserSession);
    onMount(async () => {
        const routeData = await useRouteData() as any;
        setSession(routeData.session);
        await loadIntroductionCms();
        setState("isContentReady", true);
    });
    return (
        <Show when={state.isContentReady}>
            <section class="landing-page">
                <div class="marketing">
                    <Progress step={0} />
                    <CMS id="17th-arc-salon-application/home::introduction">
                        <div style="text-align: center; font-size: 1.5em; margin-top: 40px;">
                            <p>
                                Winners For This Competition Have Now Posted
                            </p>
                        </div>
                        <div>
                            <p>
                                The list of semi-finalist artists and works for the 16th International ARC Salon Competition can be found <A href="/Salon/SemiFinalists">here</A>.
                            </p>
                            <p>
                                The list of the finalists for the 16th International ARC Salon Competition can be found <A href="/Salon/Finalists">here</A>.
                            </p>
                            <p>
                                The competition final results posting can be found <A href="/16thARCSalon/Home/Index">here</A>.
                            </p>
                            <p>
                                <b>Please come back to view the new updated prospectus and enter the 17th ARC Salon Competition, March 1 - June 13, 2024.</b>
                            </p>
                        </div>
                        <div>
                            When talking about competitions and exhibitions dedicated to 21st Century Realism, the International ARC Salon is the largest in the world in terms of entries, and the most diverse in terms of categories and international participation. This makes the International ARC Salon Competition the most influential and far-reaching competition for Contemporary Realism in the world, with over $130,000 in cash awards and international recognition through partnerships with prestigious magazines, galleries, and museums, with live exhibitions and a strong online presence. Categories include: Figurative, Portraiture, Imaginative Realism, Drawing, Landscape, Still Life, Plein Air Painting, Fully From Life, Sculpture, Animals, and a special category for teens.
                        </div>
                    </CMS>
                    <div class="row m-t-xxl" style="text-align: center;">
                        <div class="col-md-4">
                            <CMS id="salon-application/home::rules/image">
                                <p>
                                    <A href="/rules">
                                        <Img class="img-circle" src="/Media/Salon/1/Entrant/11026/Submission/11202/22aa4514-0f55-404d-8055-bf11babdd42c.jpg" mode="crop" w={300} h={300} alt="Rules" />
                                    </A>
                                </p>
                            </CMS>
                            <h2>
                                <CMS text id="salon-application/home::rules/heading">Rules</CMS>
                            </h2>
                            <p class="m-t-md m-b-md">
                                <A class="btn btn-chunky-sm btn-chunky-grey" href="/rules" role="button">
                                    <CMS text id="button::learn-more">Learn More</CMS>
                                </A>
                            </p>
                            <CMS id="salon-application/home::rules/blurb">
                                <p>
                                    Read about the rules for entering the ARC Salon and other important information.
                                </p>
                            </CMS>
                        </div>
                        <div class="col-md-4">
                            <CMS id="salon-application/home::categories/image">
                                <p>
                                    <A href="/categories">
                                        <Img class="img-circle" src="/Media/SalonApplication/categories-fleeting-dream-cropped.jpg" mode="crop" height={300} width={300} alt="Categories" />
                                    </A>
                                </p>
                            </CMS>
                            <h2>
                                <CMS text id="salon-application/home::categories/heading">Categories</CMS>
                            </h2>
                            <p class="m-t-md m-b-md">
                                <A class="btn btn-chunky-sm btn-chunky-grey" href="/categories" role="button">
                                    <CMS text id="button::learn-more">Learn More</CMS>
                                </A>
                            </p>
                            <CMS id="salon-application/home::categories/blurb">
                                <p>
                                    Artists may enter and win in one or all eleven categories; there is no limit to the number of entries you may submit.
                                </p>
                            </CMS>
                        </div>
                        <div class="col-md-4">
                            <p style="text-align: center;">
                            </p>
                            <CMS id="salon-application/home::awards/image">
                                <p>
                                    <A href="/awards">
                                        <Img class="img-circle" src="/ContentSection/23/Images/edf0c9f9-6b4d-4c3d-a321-7d098b20f6fe.jpg" mode="crop" w={300} h={300} alt="Awards"/>
                                    </A>
                                </p>
                            </CMS>
                            <p></p>
                            <h2>
                                <CMS text id="salon-application/home::awards/heading">Awards</CMS>
                            </h2>
                            <p class="m-t-md m-b-md">
                                <A class="btn btn-chunky-sm btn-chunky-grey" href="/awards" role="button">
                                    <CMS text id="button::learn-more">Learn More</CMS>
                                </A>
                            </p>
                            <CMS id="salon-application/home::awards/blurb">
                                <p>
                                    Over $130,000 in cash awards and International recognition through partnerships with prestigious magazines, galleries and museums, with live exhibitions and a strong online presence.
                                </p>
                            </CMS>
                        </div>
                    </div>
                    <div class="row m-t-xxl m-b-xxl" style="text-align: center;">
                        <div class="col-md-offset-2 col-md-4">
                            <CMS id="salon-application/home::jurors/image">
                                <p>
                                    <A href="/jurors">
                                        <Img class="img-circle" src="/Media/Salon/Jurors/fred-ross-3.jpg" mode="crop" w={300} h={300} alt="Jurors"/>
                                    </A>
                                </p>
                            </CMS>
                            <h2>
                                <CMS text id="salon-application/home::jurors/heading">Jurors</CMS>
                            </h2>
                            <p class="m-t-md m-b-md">
                                <A class="btn btn-chunky-sm btn-chunky-grey" href="/jurors" role="button">
                                    <CMS text id="button::learn-more">Learn More</CMS>
                                </A>
                            </p>
                            <CMS id="salon-application/home::jurors/blurb">
                                <p>
                                    This year the Salon will be juried by several members of our prestigious ARC Board of Judges and perhaps some special guest judges as well (four per category). Specific judges for categories still to be announced.
                                </p>
                            </CMS>
                        </div>
                        <div class="col-md-4">
                            <CMS id="salon-application/home::sponsors/image">
                                <p>
                                    <A href="/sponsors">
                                        <Img class="img-circle" src="/Media/SalonApplication/sponsors.jpg" mode="crop" w={300} h={300} alt="Sponsors"/>
                                    </A>
                                </p>
                            </CMS>
                            <h2>
                                <CMS text id="salon-application/home::sponsors/heading">Sponsors</CMS>
                            </h2>
                            <p class="m-t-md m-b-md">
                                <A class="btn btn-chunky-sm btn-chunky-grey" href="/sponsors" role="button">
                                    <CMS text id="button::learn-more">Learn More</CMS>
                                </A>
                            </p>
                            <CMS id="salon-application/home::sponsors/blurb">
                                <p>
                                    Thank you to our sponsors for their dedication to fine art, their generous donations to help the vision of an international traveling exhibition and our multi-genre competition to inspire, promote, and defend the advancement of academically inspired visual art in the 21st century.
                                </p>
                            </CMS>
                        </div>
                    </div>
                </div>
                <Show when={session.canApply || session.isAdmin}>
                    <div style="padding: 60px; margin-bottom: -40px;">
                        <h2 style="text-align: center; font-family: 'Impact';">
                            <A class="btn btn-primary" href="/step-1" style="font-size: 2rem;">
                                <CMS text id="button::update/start-application">Update/Start Application</CMS> <span class="fa fa-arrow-circle-right pulse" style="margin-left: 20px;"></span>
                            </A>
                        </h2>
                    </div>
                </Show>
            </section>
        </Show>
    );
}