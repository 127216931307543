import { SetStoreFunction } from "solid-js/store";

export const formatText = (text: string, data: Record<string,any>) => {
    console.log("formatText", { text, data });
    for (let key in data) text = text?.replace(new RegExp('{' + key + '}', 'gi'), data[key]);
    return text;
}
export const commitChange = async (typeCode: string, value: any) => {
    try {
        const property = {
            TypeCode: typeCode,
            Value: JSON.stringify(value),
            Format: "json"
        };
        const res = await fetchPost("/SalonApplication/Salon/CommitChangeJson", property);
        const json = await res.json();
        console.log("commitChange", { json });
    } catch (ex) {
        console.log("commitChange", { ex });
    }
};
export const getElementsFromHtml = (html: string, selector: string) => {
    if (!html) return [];
    const df = document.createDocumentFragment();
    const div = document.createElement("div");
    div.innerHTML = html;
    df.appendChild(div);
    const elements = Array.from(df.querySelectorAll(selector));
    return elements;
};
export const preloadImages = (urls: string[]) => {
    const frag = document.createDocumentFragment();
    urls.forEach(url => {
        const link = document.createElement("link");
        link.rel = "preload";
        link.as = "image";
        link.href = url;
        frag.appendChild(link);
    });
    document.head.appendChild(frag);
};
export const toFormData = (obj: any) => {
    let formData = new FormData();
    for (let key in obj) {
        let value = obj[key];
        formData.append(key, value);
    }
    return formData;
};
export const cleanImagePath = (filepath: string) => {
    if (!filepath) return filepath;
    filepath = filepath.replace(new RegExp('~', 'g'), '');
    filepath = filepath.replace(new RegExp(':', 'g'), '_');
    filepath = filepath.replace(new RegExp('//', 'g'), '/');
    filepath = filepath.replace(new RegExp('\\\\', 'g'), '/');
    var url = encodeURI("/secureimages" + filepath);
    return url;
};
const cache: any = {};
export const getOrSet = (key: string, getter: () => any) =>{
    if (!!cache[key]) {
        return cache[key];
    }
    const value = getter();
    cache[key] = value;
    return value;
};
export function toggleCheckbox<TModel = Record<string,any>>(model: TModel, setModel: SetStoreFunction<TModel>, key: keyof TModel, value: string) {
    const parts = (model[key] as string)?.split(",");
    if (parts.some(x => x == value)) {
        const filtered = parts.filter(x => x != value).join(",");
        setModel(key, filtered);
    } else {
        const updated = [...parts, value].join(",");
        setModel(key, updated);
    }
}
function nullSafe<TModel>(model: TModel, key: keyof TModel) {
    return model[key] && model[key] != "undefined" && model[key] != "null" ? model[key] : "";
};
function nullSafety<TModel = Record<string, any>>(obj: TModel): TModel {
    var result: any = {};
    const keys = Object.keys(obj) as (keyof TModel)[];
    keys.forEach(key => {
        const value = obj[key as keyof TModel] as any;
        if (value === undefined || value === null) return;
        if (typeof value === "object" && !(value instanceof Blob) && !(value instanceof File)) return;
        if (key == "Id" && (value == "0" || value == 0)) return;
        result[key] = typeof value === "string" ? nullSafe(obj, key as keyof TModel) : value;
    });
    return result as TModel;
};
export const fetchGet = async (url: string, params: any) => {
    return await fetch(url + "?" + toQueryString(nullSafety(params)));
};
type FetchPostSettings = {
    includeNullFields?: boolean;
    useJson?: boolean;
}
export const fetchPost = async (url: string, data: any, settings?: FetchPostSettings) => {
    if (settings) {
        const _data = settings.includeNullFields ? data : nullSafety(data);
        const body = settings.useJson ? JSON.stringify(_data) : toFormData(_data);
        const headers = settings.useJson ? { "Content-Type": "application/json" } : { "Content-Type": "text/plain;charset=UTF-8" };
        console.log("fetchPost", { url, body, headers, settings });
        const response = await fetch(url, {
            method: 'POST',
            body: body,
            headers: headers
        });
        return response;
    } else {
        const body = toFormData(nullSafety(data));
        console.log("fetchPost", { url, body, settings });
        const response = await fetch(url, {
            method: 'POST',
            body: body
        });
        return response;
    }
};
export const scrollToTop = () => window.scrollTo(0, 0);
export const getOrSetAsync = async (key: string, getter: () => any) =>{
    if (!!cache[key]) {
        return cache[key];
    }
    const value = await getter();
    cache[key] = value;
    return value;
};
export const toQueryString = (data: {}): string =>{
    let _data = {};
    for (let key in data) {
        let value = data[key];
        if (value === null || value === "null") {
            continue;
        }
        _data[key] = value;
    }
    const queryString = new URLSearchParams(_data);
    return queryString.toString();
}