import { Accessor, Component, JSXElement, Match, Show, Switch, createEffect, onMount } from "solid-js"
import { Address, ListItem } from "../types"
import { Required } from "./universal/required-icon"
import { ErrorMessage, useForm } from "./universal/validation"
import { createStore } from "solid-js/store"
import { TextBox } from "./text-box"
import { Select } from "./select"
import { Options } from "./options"
import { CMS, plainText } from "./cms/cms"
import { getText, loadCms } from "../app"

type Props = {
    model: Address;
    onChange: (address: any) => Promise<string|undefined>;
    countries: Accessor<ListItem[]>;
}
type Resources = {
    countries: ListItem[];
}
type State = {
    isContentReady: boolean;
}
export const AddressForm : Component<Props> = (props) => {
    const { formSubmit, validate, errors } = useForm({
        errorClass: "error-input"
    });
    const [state, setState] = createStore<State>({
        isContentReady: false
    });
    const [resources, setResources] = createStore<Resources>({
        countries: props.countries()
    });
    const requiredListItem = ({ value }: any) => {
        console.log("requiredListItem", { value });
        return (!value || value == "null") && "Please select from the list";
    }
    const [model, setModel] = createStore<Address>({
        streetLine1: props.model.streetLine1,
        streetLine2: props.model.streetLine2,
        city: props.model.city,
        stateName: props.model.stateName,
        countryId: props.model.countryId,
        postalCode: props.model.postalCode
    });
    const ModelSelect : Component<{ field: keyof Address, list: ListItem[]; label?: string; id?: string;  required?: boolean; }> = (props) =>
    <>
        <Show when={props.id}>
            <label>
                <Show when={props.required}><Required /></Show>
                {getText(props.id as string, props.label as string)}
            </label>
        </Show>
        <Show when={!props.id}>
            <label>
                <Show when={props.required}><Required /></Show>
                {props.label || props.field}
            </label>
        </Show>
        <Switch>
            <Match when={props.required}>
                <select
                    value={model[props.field]}
                    onInput={(e) => setModel(props.field, e.currentTarget.value)}
                    name={props.field}
                    required
                    use:validate={[requiredListItem]}
                    class="form-control">
                    <Options list={props.list} />
                </select>
                <ErrorMessage error={errors[props.field]} />
            </Match>
            <Match when={!props.required}>
                <Select value={model[props.field] as string} list={props.list} onInput={(value) => setModel(props.field, value)} />        
            </Match>
        </Switch>
    </>;
    type ModelTextBoxProps = { field: keyof Address; label?: string|(() => JSXElement); id?: string; format?: string; required?: boolean; placeholder?: string; };
    const ModelTextBox : Component<ModelTextBoxProps> = (props) => {
        return ( 
        <>
            <Show when={props.id}>
                <label>
                    <Show when={props.required}><Required /></Show>
                    {getText(props.id as string, props.label as string)}
                </label>
            </Show>
            <Show when={!props.id}>
                <Show when={props.label}>
                    <Show when={props.label instanceof String}>
                        <label>
                            <Show when={props.required}><Required /></Show>
                            {props.label as string}
                        </label>
                    </Show>
                    <Show when={!(props.label instanceof String)}>
                        <label>
                            <Show when={props.required}><Required /></Show>
                            {(props.label as () => JSXElement)()}
                        </label>
                    </Show>
                </Show>
                <Show when={!props.label}>
                    <label>
                        <Show when={props.required}><Required /></Show>
                        {props.field}
                    </label>
                </Show>
            </Show>
            <Switch>
                <Match when={props.required}>
                    <input
                        value={model[props.field] as string}
                        class="form-control"
                        name={props.field}
                        placeholder={props.placeholder}
                        required
                        use:validate={[]}
                        onInput={(e) => setModel(props.field, e.currentTarget.value)}
                    />
                    <ErrorMessage error={errors[props.field]} />
                </Match>
                <Match when={!props.required}>
                    <TextBox value={model[props.field] as string} onInput={(value) => setModel(props.field, value)} />    
                </Match>    
            </Switch>
        </>
        )
    };
    const onSubmit = () => {
        props.onChange(model);
    }
    onMount(async () => {
        const ids = [
            "button::save-changes","form::shipping-address/heading",
            "label::street-line-1","label::street-line-2",
            "label::state-province","label::city","label::postal-code-zip",
            "label::country"
        ];
        await loadCms(ids);
        setState({ isContentReady: true });
        setModel(props.model);
    });
    return (
        <Show when={state.isContentReady}>
            <div>
                <div>
                    <CMS id="form::shipping-address/heading" text>Shipping Address</CMS>
                </div>
                <form use:formSubmit={onSubmit}>
                    <div class="row">
                        <div class="col-md-6">
                            <ModelTextBox field="streetLine1" label="Street (line 1)" id="label::street-line-1" required />
                        </div>
                        <div class="col-md-6">
                            <ModelTextBox field="streetLine2" label="Street (line 2)" id="label::street-line-2" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <ModelTextBox field="city" label="City" required id="label::city" />
                        </div>
                        <div class="col-md-6">
                            <ModelTextBox field="stateName" label="State/Province" id="label::state-province" required />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <ModelTextBox field="postalCode" label="Post code (ZIP)" id="label::postal-code-zip" required />
                        </div>
                        <div class="col-md-6">
                            <ModelSelect field="countryId" list={resources.countries} label="Country" id="label::country" required />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <button class="btn btn-primary" type="submit">
                                <CMS id="button::save-changes" text>Save Changes</CMS>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </Show>
    )
}