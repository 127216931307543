import { Component, createSignal, Show } from "solid-js";
import { UserSession } from "../types";
import { TextInput } from "../components/form-controls";
import { FormCheck, Offcanvas } from "solid-bootstrap";
import { ForgotPassword } from "./forgot-password";
import { fetchGet } from "../library/common";

export type LoginProps = {
    onCancel: () => void;
    onSuccess: (session: UserSession) => void;
    onRegistrationRequired: () => void;
};

export const Login : Component<LoginProps> = (props) => {
    const [showForgotPasswordModal, setShowForgotPasswordModal] = createSignal<boolean>(false);
    const [error, setError] = createSignal<string>("");
    const [username, setUsername] = createSignal<string>("");
    const [password, setPassword] = createSignal<string>("");
    const [attempts, setAttempts] = createSignal<number>(0);
    const [remember, setRemember] = createSignal<boolean>(false);
    const maxAttempts = 5;
    const getModel = () => {
        return {
            username: username(),
            password: password(),
            remember: remember(),
            returnUrl: ""
        };
    };
    const canceClicked = (e: Event) => {
        e.preventDefault();
        props.onCancel();
    }
    const login = async () => {
        const model = getModel();
        const response = await fetchGet("/Forums/User/LoginJson", model);
        const json = await response.json();
        if (!json.Success) {
            setError("Username or password did not match. Please try again.");
            return;
        }
        const data = json.Data;
        if (data.registrationRequired) {
            props.onRegistrationRequired();
            return;
        }
        props.onSuccess({
            isLoggedIn: true,
            isRegistered: true,
            member: data.member,
            communityMember: data.communityMember
        });
    };
    
    const forgotPasswordClicked = (e: Event) => {
        openForgotPassword();
    };
    const openForgotPassword = () => {
        setShowForgotPasswordModal(true);
    };
    const closeForgotPassword = () => {
        setShowForgotPasswordModal(false);
    };
    const forgotPasswordCompleted = () => {
        closeForgotPassword();
        props.onCancel();
    };
    const onSubmit = async (e: Event) => {
        e.preventDefault();
        await login();
    };
    return (
        <>
            <Show when={error()}>
                <div style="color: red;">
                    {error()}
                </div>    
            </Show>
            <Show when={attempts() > 0}>
                <div>
                    You have <b>{maxAttempts - attempts()} more attempts</b> to log in before your account is locked for 24 hours.
                </div>
            </Show>
            <form onSubmit={onSubmit}>
                <div>
                    <label>
                        Username
                    </label>
                    <TextInput field={[username, setUsername]}></TextInput>
                </div>
                <div>
                    <label>
                        Password
                    </label>
                    <input
                        type="password"
                        class="form-control"
                        value={password()}
                        onChange={(e) => setPassword(e.currentTarget.value)} />
                </div>
                <div>
                    <FormCheck checked={remember()} onChange={() => setRemember(!remember()) }></FormCheck> Stay logged in?
                </div>
                <div>
                    <a onClick={forgotPasswordClicked}>Forgot password?</a>
                </div>
                <div>
                    <button type="button" class="btn btn-default mr-20" onClick={canceClicked}>Cancel</button>
                    <button type="submit" class="btn btn-primary">Login</button>
                </div>
            </form>
            <Offcanvas show={showForgotPasswordModal()} placement="end" onHide={closeForgotPassword}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Forgot Password</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <ForgotPassword onCancel={closeForgotPassword} onCompleted={forgotPasswordCompleted}></ForgotPassword>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};