
import { Component, splitProps } from "solid-js";

interface TextBoxProps extends Record<any,any> {
    value: string;
    onInput: (value: string) => void;
}

export const TextBox : Component<TextBoxProps>=(props) => {
    const [local, others] = splitProps(props, ["value", "onInput"]);
    return (<input 
        type="text"
        class="form-control"
        value={local.value}
        onInput={(e) => local.onInput(e.currentTarget.value)}
        {...others}
    />)
}